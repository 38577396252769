import React, { useContext } from "react";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { templateTypes } from "../../../../../config/template.config";
import {
  FormWrapper,
  InputBoxWithState,
  RadioButtonsGroupWithState,
} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getRoutePermitStatus } from '../../../../../helpers/common-helpers/getRoutePermitStatus.helper'

const test = false;

const EditPermit = (props) => {
  const formKey = "editPermit";
  const [formState, formAction] = useContext(FormContext);
  return (
    <FormWrapper
      setGroupName={formKey}
      setFormObject={{
        id: _get(props.data, "row.id", undefined),
        vehicle_id: _get(props.data, "row.vehicle_reg_no", ""),
        status:getRoutePermitStatus(_get(props.data,`row.status`, ''),_get(props.data,`row.expiry_date`, '')),
        unchangeStatus:_get(props.data, "row.status", ""),
        expiryDate:_get(props.data,"row.expiry_date", "")
      }}
    >
      <InputBoxWithState
        labelText={"Vehicle ID"}
        formGroupName={formKey}
        inputName={"vehicle_id"}
        inputValue={_get(props.data, `row.vehicle_reg_no`, '')}
        isDisabled='true'
      />

      <InputBoxWithState
        labelText={"Route Permit No."}
        formGroupName={formKey}
        inputName={"rp_number"}
        inputValue={_get(props.data, `row.route_permit_no`, '')}
        isDisabled={true}
      />

      <InputBoxWithState
        labelText={"Issued Date"}
        formGroupName={formKey}
        inputName={"issued_date"}
        inputValue={dateObjectToString(_get(props.data,`row.issued_at`, ''), "dd/mm/YY")}
        isDisabled={true}
      />

      <InputBoxWithState
        labelText={"Expiry Date"}
        formGroupName={formKey}
        inputName={"expiry_date"}
        inputValue={dateObjectToString(_get(props.data,`row.expiry_date`, ''), "dd/mm/YY")}
        isDisabled={true}
      />

      <div className="pl-0">
      { getRoutePermitStatus(_get(props.data,`row.status`, ''),_get(props.data,`row.expiry_date`, '')) != 'Expired' &&
        <RadioButtonsGroupWithState
          uiType={templateTypes.material}
          labelText="Status"
          inputName={"status"}
          formGroupName={formKey}
          inputValue={_get(props.data, `row.status`, '')}
          dataList={[
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "Inactive",
              label: "Inactive",
            },
          ]}
        />
}
      </div>

      {getRoutePermitStatus(_get(props.data,`row.status`, ''),_get(props.data,`row.expiry_date`, '')) == 'Expired' &&
        <RadioButtonsGroupWithState
        uiType={templateTypes.material}
        labelText="Status"
        inputName={"status"}
        disabled
        formGroupName={formKey}
        inputValue={getRoutePermitStatus(_get(props.data,`row.status`, ''),_get(props.data,`row.expiry_date`, ''))}
        dataList={[
          {
            value: "Expired",
            label: "Expired",
          },
          {
            value: "Inactive",
            label: "Inactive",
          },
        ]}
      />
      }

      {_get(formState[formKey], "status", "") === 'Inactive' &&
          <InputBoxWithState
          labelText={"Reasons for Inactive"}
          formGroupName={formKey}
          inputName={"updated_reason"}
          isRequired={true}
          isMultiline={true}
          rows={3}
          isDisabled={false}
          maxLength={200}
        />
      }


    </FormWrapper>
  );
}


export { EditPermit };
