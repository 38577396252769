import React, { Fragment } from "react";
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import {IssueRoutePermit} from "./pages/IssueRoutePermit";
import {permissions} from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";
import authorized from '../../hoc-middlewares/Authorized';


const IssueRoutePermitRoutes = () => {
  return (
    <Fragment>
      <Navigate path="/" exact={true} routeKey={"issue_route_permit"} component={authorized(IssueRoutePermit)} routePermissions={permissions.NONE.permissions}/>
       
    </Fragment>
  );
};
export default IssueRoutePermitRoutes;
