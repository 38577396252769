/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:31:46
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-15 15:26:02
 */

import React, { Fragment } from "react";
import ViewPermits from "./pages/ViewPermits";
import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import { permissions } from "../../../config/permission.config";
import authorized from '../../hoc-middlewares/Authorized';

const ViewPermitsRoutes = () => {
  return (
    <Fragment>
      <Navigate
        exact
        path="/view-permits"
        routeKey={"view-permits"}
        component={authorized(ViewPermits)}
        routePermissions={permissions.NONE.permissions}
      />
    </Fragment>
  );
};

export default ViewPermitsRoutes;
