/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-05-08 16:50:52
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-05-08 16:50:52
 */
import React , { Fragment }  from 'react';

import { Navigate } from "../../ui-components/ui-elements/common/Navigate";
import PageError from "./pages/PageError";
import {PermissionDenial} from "./pages/PermissionDenial";
import {Forbidden} from "./pages/Forbidden";
import authorized from "../../hoc-middlewares/Authorized";

const ErrorBoundaryRoutes = () => {
    return (
        <Fragment>
        <Navigate path="/page-error" exact={true} component={PageError} />
        <Navigate path="/permission-denied" exact={true} component={PermissionDenial} />
        <Navigate path="/forbidden" exact={true} component={authorized(Forbidden)} />
        </Fragment>
    )
};

export default ErrorBoundaryRoutes;
