/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-06-12 15:41:23 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-06-15 10:48:33
 */

export const permissions={
    "NONE":{
        "key":"NONE",
        "description":"",
        "permissions":"NONE"
    },
    "LOGGED_IN":{
        "key":"LOGGED_IN",
        "description":"",
        "permissions":[
            "VALID_JWT"
        ]
    },
    "EDIT_PROFILE":{
        "key":"EDIT_PROFILE",
        "description":"",
        "permissions":[
            "VALID_JWT"
        ]
    },
    "VIEW_TASK":{
        "key":"VIEW_TASK",
        "description":"",
        "permissions":[
            "VALID_JWT"
        ]
    },
    "VIEW_PENDING_TASK":{
        "key":"VIEW_PENDING_TASK",
        "description":"",
        "permissions":[
            "VALID_JWT"
        ]
    },
    "VIEW_DASHBOARD":{
        "key":"VIEW_DASHBOARD",
        "description":"",
        "permissions":[
            "VALID_JWT"
        ]
    },
    "VIEW_MASTER_DATA":{
        "key":"VIEW_DASHBOARD",
        "description":"",
        "permissions":[
            "VIEW_COMPANY","VIEW_LOC_TYPE","VIEW_EQ_TYPE",
            "VIEW_HR_TYPE","VIEW_CHK_LIST","VIEW_INSPECTION_REQ"
        ]
    },
    "VIEW_COMPANY":{
        "key":"VIEW_COMPANY",
        "description":"",
        "permissions":[
            "VIEW_COMPANY"
        ]
    },
    "ALTER_COMPANY":{
        "key":"ALTER_COMPANY",
        "description":"",
        "permissions":[
            "ALTER_COMPANY"
        ]
    },
    "VIEW_BRANCH":{
        "key":"VIEW_BRANCH",
        "description":"",
        "permissions":[
            "VIEW_COMPANY"
        ]
    },
    "ALTER_BRANCH":{
        "key":"ALTER_BRANCH",
        "description":"",
        "permissions":[
            "ALTER_COMPANY"
        ]
    },
    "VIEW_LOC_TYPE":{
        "key":"VIEW_LOC_TYPE",
        "description":"",
        "permissions":[
            "VIEW_LOC_TYPE"
        ]
    },
    "ALTER_LOC_TYPE":{
        "key":"ALTER_LOC_TYPE",
        "description":"",
        "permissions":[
            "ALTER_LOC_TYPE"
        ]
    },
    "VIEW_EQ_TYPE":{
        "key":"VIEW_EQ_TYPE",
        "description":"",
        "permissions":[
            "VIEW_EQ_TYPE"
        ]
    },
    "ALTER_EQ_TYPE":{
        "key":"ALTER_EQ_TYPE",
        "description":"",
        "permissions":[
            "ALTER_EQ_TYPE"
        ]
    },
    "VIEW_HR_TYPE":{
        "key":"VIEW_HR_TYPE",
        "description":"",
        "permissions":[
            "VIEW_HR_TYPE"
        ]
    },
    "ALTER_HR_TYPE":{
        "key":"ALTER_HR_TYPE",
        "description":"",
        "permissions":[
            "ALTER_HR_TYPE"
        ]
    },
    "VIEW_CHK_LIST":{
        "key":"VIEW_CHK_LIST",
        "description":"",
        "permissions":[
            "VIEW_CHK_LIST"
        ]
    },
    "ALTER_CHK_LIST":{
        "key":"ALTER_CHK_LIST",
        "description":"",
        "permissions":[
            "ALTER_CHK_LIST"
        ]
    },
    "VIEW_INSPECTION_REQ":{
        "key":"VIEW_INSPECTION_REQ",
        "description":"",
        "permissions":[
            "VIEW_INSPECTION_REQ"
        ]
    },
    "ALTER_INSPECTION_REQ":{
        "key":"ALTER_INSPECTION_REQ",
        "description":"",
        "permissions":[
            "ALTER_INSPECTION_REQ"
        ]
    },
    "VIEW_USER":{
        "key":"VIEW_USER",
        "description":"",
        "permissions":[
            "VIEW_USER"
        ]
    },
    "ALTER_USER":{
        "key":"ALTER_USER",
        "description":"",
        "permissions":[
            "ALTER_USER"
        ]
    },
    "VIEW_ROLE":{
        "key":"VIEW_ROLE",
        "description":"",
        "permissions":[
            "VIEW_ROLE"
        ]
    },
    "ALTER_ROLE":{
        "key":"ALTER_ROLE",
        "description":"",
        "permissions":[
            "ALTER_ROLE"
        ]
    },
    "VIEW_REPORT":{
        "key":"VIEW_REPORT",
        "description":"",
        "permissions":[
            "VIEW_INSPECTION_REPORT"
        ]
    },
    "VIEW_INSPECTION_REPORT":{
        "key":"VIEW_INSPECTION_REPORT",
        "description":"",
        "permissions":[
            "VIEW_INSPECTION_REPORT"
        ]
    },
    "VIEW_RESOURCE":{
        "key":"VIEW_RESOURCE",
        "description":"",
        "permissions":[
            "VIEW_EQ","VIEW_LOC","VIEW_SUB_LOC","VIEW_HR","VIEW_VISITOR"
        ]
    },
    "VIEW_EQ":{
        "key":"VIEW_EQ",
        "description":"",
        "permissions":[
            "VIEW_EQ"
        ]
    },
    "ALTER_EQ":{
        "key":"ALTER_EQ",
        "description":"",
        "permissions":[
            "ALTER_EQ"
        ]
    },
    "VIEW_LOC":{
        "key":"VIEW_LOC",
        "description":"",
        "permissions":[
            "VIEW_LOC"
        ]
    },
    "ALTER_LOC":{
        "key":"ALTER_LOC",
        "description":"",
        "permissions":[
            "ALTER_LOC"
        ]
    },
    "VIEW_SUB_LOC":{
        "key":"VIEW_SUB_LOC",
        "description":"",
        "permissions":[
            "VIEW_SUB_LOC"
        ]
    },
    "ALTER_SUB_LOC":{
        "key":"ALTER_SUB_LOC",
        "description":"",
        "permissions":[
            "ALTER_SUB_LOC"
        ]
    },
    "VIEW_HR":{
        "key":"VIEW_HR",
        "description":"",
        "permissions":[
            "VIEW_HR"
        ]
    },
    "ALTER_HR":{
        "key":"ALTER_HR",
        "description":"",
        "permissions":[
            "ALTER_HR"
        ]
    },
    "VIEW_VISITOR":{
        "key":"VIEW_VISITOR",
        "description":"",
        "permissions":[
            "VIEW_VISITOR"
        ]
    },
    "CREATE_VISITOR":{
        "key":"CREATE_VISITOR",
        "description":"",
        "permissions":[
            "CREATE_VISITOR"
        ]
    },
    "CREATE_ADHOC_INSPECTION":{
        "key":"CREATE_ADHOC_INSPECTION",
        "description":"",
        "permissions":[
            "CREATE_ADHOC_INSPECTION"
        ]
    },
    "CREATE_INSPECTION":{
        "key":"CREATE_INSPECTION",
        "description":"",
        "permissions":[
            "CREATE_INSPECTION"
        ]
    },
    "CREATE_CORRECTION":{
        "key":"CREATE_CORRECTION",
        "description":"",
        "permissions":[
            "CREATE_CORRECTION"
        ]
    },
    "CREATE_CORRECTIVENESS_ACTION":{
        "key":"CREATE_CORRECTIVENESS_ACTION",
        "description":"",
        "permissions":[
            "CREATE_CORRECTIVENESS_ACTION"
        ]
    },
    "CREATE_EFFECTIVENESS_REVIEW":{
        "key":"CREATE_EFFECTIVENESS_REVIEW",
        "description":"",
        "permissions":[
            "CREATE_EFFECTIVENESS_REVIEW"
        ]
    },
    "APPROVE_CORRECTION":{
        "key":"APPROVE_CORRECTION",
        "description":"",
        "permissions":[
            "APPROVE_CORRECTION"
        ]
    },
    "APPROVE_CORRECTIVENESS_ACTION":{
        "key":"APPROVE_CORRECTIVENESS_ACTION",
        "description":"",
        "permissions":[
            "APPROVE_CORRECTIVENESS_ACTION"
        ]
    },
    "APPROVE_EFFECTIVENESS":{
        "key":"APPROVE_EFFECTIVENESS",
        "description":"",
        "permissions":[
            "APPROVE_EFFECTIVENESS"
        ]
    },
    "APPROVE_SKIP_INSPECTION":{
        "key":"APPROVE_SKIP_INSPECTION",
        "description":"",
        "permissions":[
            "APPROVE_SKIP_INSPECTION"
        ]
    },
    "APPROVE_VISITOR":{
        "key":"APPROVE_VISITOR",
        "description":"",
        "permissions":[
            "APPROVE_VISITOR"
        ]
    },
    "APPROVE_RE_INSPECTION":{
        "key":"APPROVE_RE_INSPECTION",
        "description":"",
        "permissions":[
            "APPROVE_RE_INSPECTION"
        ]
    }
};