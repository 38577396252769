/*
 * @Author: Pradeepa Sandaruwan
 * @Date:  2021-01-15 12:51:24
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-15 14:33:44
 */

import React from 'react';
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { isEmpty } from 'lodash';
import { getDecodedValue } from '../../../../../helpers/common-helpers/common.helpers';

const NewRoutePermitInfo = ({
    getState = {}
}) => {

    const getExpiryDate = (date) => {
        if (date) {
            return dateObjectToString(_get(date, "date", ''), "dd/mm/YY")
        } else {
            return ''
        }

    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 defaultMarginBottom">
                        <h1 className="defaultHeading">Vehicle Information</h1>

                        <div className={"col-md-12"}>
                            <strong>Vehicle ID :</strong> {getState.vehicleObject.vehicleId}
                        </div>

                        <div className={"col-md-12"}>
                            <strong>Last Six Characters of Chassis Number :</strong> {`${getDecodedValue(getState.vehicleObject.chassisNumber, true, false)}`.slice(-6)}
                        </div>

                        <div className={"col-md-12"}>
                            <strong>Vehicle Class :</strong>  {getState.vehicleObject.class}
                        </div>
                    </div>
                    <div className="col-md-6 defaultMarginBottom">
                        <h1 className="defaultHeading">Owner Information</h1>

                        <div className={"col-md-12"}>
                            <strong>Owner’s Name :</strong> {getDecodedValue(getState.vehicleObject.owner, true, false)}
                        </div>

                        <div className={"col-md-12"}>
                            <strong>Owner’s Address :</strong> {getDecodedValue(getState.vehicleObject.address, true, false)}
                        </div>
                    </div>
                    <div className="col-md-6 defaultMarginBottom">
                        <h1 className="defaultHeading">Revenue Licence Information</h1>

                        <div className={"col-md-12"}>
                            <strong>Current RL No :</strong> {getState.vehicleObject.currentRL}
                        </div>


                        <div className={"col-md-12"}>
                            <strong>RL Expiry Date :</strong> {_get(getState.vehicleObject, "rlExpiryDate", "") ? dateObjectToString((_get(getState.vehicleObject, "rlExpiryDate", "")), "dd/mm/YY") : '-'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    NewRoutePermitInfo
}