import React, { useContext } from "react";
import { templateTypes } from "../../../../../config/template.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import {
  FormWrapper,
  InputBoxWithState,
  RadioButtonsGroupWithState,
  DatePickerWithState,
} from "../../../../ui-components/ui-elements/form";

import { getRoutePermitStatus } from '../../../../../helpers/common-helpers/getRoutePermitStatus.helper'
import { _get , } from "../../../../../helpers/common-helpers/lodash.wrappers";


const EditRoutePermit = (props) => {
  const formKey = "editRoutePermit";
  const [formState, formAction] = useContext(FormContext);
    return (
      <div className="col-md-12">
        <FormWrapper
         setGroupName={formKey}
         setFormObject={{
          id: _get(props.getState.vehicleObject, "id", undefined),
          vehicle_id: _get(props.getState.vehicleObject, "vehicleId", ""),
          status:_get(props.getState.routePermitObject, "status", ""),
          routePermitId:_get(props.getState.routePermitObject, "routePermitId", ""),
        }}
        >
          <InputBoxWithState
            labelText={"Vehicle ID"}
            formGroupName={formKey}
            inputName={"vehicle_id"}
            inputValue={props.getState.vehicleObject.vehicleId}
            isDisabled='true'
          />
          <InputBoxWithState
            labelText={"Route Permit No."}
                formGroupName={formKey}
            inputName={"rp_number"}
            inputValue={props.getState.routePermitObject.routePermitNo}
            isDisabled={true}
          />


          <DatePickerWithState
             labelText={"Issued Date"}
                    formGroupName={formKey}
                    inputName={"issued_date"}
                    format={'dd/MM/yyyy'}
                    inputValue={props.getState.routePermitObject.issuedDate}
                    isDisabled={true}
                />

            <DatePickerWithState
                  labelText={"Expiry Date"}
                       formGroupName={formKey}
                    inputName={"expiry_date_old"}
                    format={'dd/MM/yyyy'}
                    inputValue={props.getState.routePermitObject.expiryDate}
                    isDisabled={true}
                />
  
          <div className="row defaultPaddingLeft">
            <RadioButtonsGroupWithState
              uiType={templateTypes.material}
              labelText="Status"
              inputName={"status"}
              formGroupName={formKey}
              inputValue={props.getState.routePermitObject.status}
              dataList={[
                {
                  value: "Active",
                  label: props.getState.routePermitObject.status ==='Active' &&  new Date(props.getState.routePermitObject.expiryDate) < new Date()? "Expired" :"Active",
                },
                {
                  value: "Inactive",
                  label: "Inactive",
                },
              ]}
            />
          </div>
            
          {_get(formState[formKey], "status", "") === 'Inactive' &&
          <InputBoxWithState
          labelText={"Reasons for Inactive"}
          formGroupName={formKey}
          inputName={"updated_reason"}
          isRequired={true}
          isMultiline={true}
          rows={3}
          isDisabled={false}
          maxLength={200}
        />
      }
        </FormWrapper>
      </div>
    )
  };

  export {EditRoutePermit}