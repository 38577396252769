import React from "react"
import { PaddingLessUICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import { UICard } from "../../../../ui-components/ui-elements/common/BaseElements";
import {FormWrapper,} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import {RoutePermitInfo} from "./RoutePermitInfo"
import {RoutePermitDetailsIcons} from "./RoutePermitDetailsIcons"

const IssuedPermitDetails = (props)=>{
    return(
       
        <FormWrapper
        setGroupName={"routePermit"}
        elementStyle={"normalLicenceWrapper"}
      >
        <UICard elementStyle="col-md-12 floatLeft">
          <br />
          <PaddingLessUICard
            cardHeading="Route Permit Information"
            passPropsToCardHeadingTail={{
              uiType: "owner_infor",
              formGroupName: "routePermit",
              getState:props.getState,
              setState :props.setState,
              getLatestRoutePermitandUpdateState:props.getLatestRoutePermitandUpdateState
            }}
            cardHeadingTail={RoutePermitDetailsIcons}
          >
          <RoutePermitInfo 
                   getState={props.getState}
                   setState = {props.setState}
          />
          </PaddingLessUICard>
        </UICard>

      </FormWrapper>

    )
}
export {IssuedPermitDetails}