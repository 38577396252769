/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-21 11:09:29 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-05-12 18:32:32
 */

import React,{useContext,Fragment} from 'react';

import {CircleLoaderElement} from "../../ui-elements/common/BaseElements";
import {UIContext} from "../../context-providers/UIContext.provider";

const PageLoader=()=>{
    const [state]=useContext(UIContext);
    return (
        <Fragment>
            {
                (state.setPageLoader===true) ? (
                    <div className="pageLoader">
                        <CircleLoaderElement 
                            loaderStyle={"loader"} 
                            loaderSize={50} 
                            loaderThickness={3} 
                        />
                    </div>
                ) : (null)
            }
        </Fragment>
    );
}


export {
    PageLoader
}