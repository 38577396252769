/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-07 11:41:29 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-07 19:03:37
 */
import React,{useContext} from "react";
import {UIContext} from "../../context-providers/UIContext.provider";
import { FullMenu } from "../common-includes/menu-includes/FullMenu";

 const TemplateTwoSideMenu=()=>{
    const [uiStatus,dispatchUIFn]=useContext(UIContext);

    const changeToggle=(status=true)=>{
        if(uiStatus.fixedMenu===false){
            dispatchUIFn.setToggleSideMenuFn(status)
        }
    }

     return (
        <div className={`templateTwoSideMenu ${(uiStatus.fixedMenu===false && uiStatus.toggleSideMenu===false)?"templateTwoSideMenuHide":"templateTwoSideMenuShow"}`} onMouseLeave={()=>changeToggle(false)} onMouseEnter={()=>changeToggle(true)}>
            <div className={`subMenuHeader ${uiStatus.fixedMenu===true?"sideMenuLargePadding":"sideMenuPadding"}`}>
                <img
                    className="logo"
                    src={'images/eRLLogofinal03_1.png'}
                    alt="logo"
                />                
                <i className={`mdi ${uiStatus.fixedMenu===true?"mdi-menu menuIcon":"mdi-close closeIcon"} iconToggal`} onClick={()=>dispatchUIFn.setFixedSideMenuFn()}></i>
            </div>
            <div className={`subMenuWrapper ${uiStatus.fixedMenu===true?"sideMenuLargePadding":"sideMenuPadding"}`}>
                <FullMenu/>
            </div>
        </div>
     )
 }

 export {
    TemplateTwoSideMenu
 }