import React, { Fragment } from "react";
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import authorized from '../../hoc-middlewares/Authorized';
import ReportVrp from "./pages/ReportVrp";
import {permissions} from "../../../config/permission.config";
import guest from "../../hoc-middlewares/Guest";


const ReportRoutes = () => {
  return (
    <Fragment>
      <Navigate path="/report-vrp" exact={true} routeKey={"reportvrp"} component={authorized(ReportVrp)}  routePermissions={permissions.NONE.permissions}/>
       
    </Fragment>
  );
};
export default ReportRoutes;
