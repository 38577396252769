/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-07-14 10:18:15
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-18 14:03:18
 */

import React, { Fragment } from "react";
import { PaddingLessUICard, ViewDetailBlock } from "../../../../ui-components/ui-elements/common/BaseElements";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getRoutePermitStatus } from '../../../../../helpers/common-helpers/getRoutePermitStatus.helper'
import { Base64 } from "js-base64";
const ViewPermitDetails = (props) => {
    return (
        <Fragment>
            <div className="fullWidthDiv">
                <PaddingLessUICard cardHeading="Vehicle Information">
                    <ViewDetailBlock
                        label="Vehicle ID"
                        value={_get(props, `row.vehicle_reg_no`, '')}
                        labelCol={6}
                        
                    />
                    <ViewDetailBlock
                        label="Last Six Characters of the Chassis Number"
                        value={Base64.decode(_get(props, `row.chassis_no`, '')).slice(-6)}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Vehical Class"
                        value={_get(props, `row.vehicle_class`, '')}
                        labelCol={6}
                    />
                </PaddingLessUICard>
            </div>

            <div className="fullWidthDiv">
                <PaddingLessUICard cardHeading="Owner Information">
                    <ViewDetailBlock
                        label="Owner's Name"
                        value={Base64.decode(_get(props, `row.owner_name`, ''))}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Owner's Address"
                        value={Base64.decode(_get(props, `row.owner_address_line1`, ''))}
                        labelCol={6}
                    />
                </PaddingLessUICard>
            </div>

            <div className="fullWidthDiv">
                <PaddingLessUICard cardHeading="Route Permit Information">
                    <ViewDetailBlock
                        label="Route Permit No."
                        value={_get(props, `row.route_permit_no`, '')}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Issued Date"
                        value={dateObjectToString(_get(props,`row.issued_at`, ''), "dd/mm/YY")}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Expiry Date"
                        value={dateObjectToString(_get(props,`row.expiry_date`, ''), "dd/mm/YY")}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Status"
                        value={getRoutePermitStatus(_get(props,`row.status`, ''),_get(props,`row.expiry_date`, ''))}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Last Updated by"
                        value={_get(props, `row.updated_by_name`, '')}
                        labelCol={6}
                    />
                    <ViewDetailBlock
                        label="Last Updated at"
                        value={dateObjectToString(_get(props,`row.updated_at`, ''), "dd/mm/YY HH:MM")}
                        labelCol={6}
                    />
                </PaddingLessUICard>
            </div>
        </Fragment>
    );
};



export { ViewPermitDetails };
