/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-26 20:17:06
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-15 15:19:15
 */
import { permissions } from "./permission.config";

const siteMenu = [
  // {
  //   labelText: "Dashboard",
  //   routeName: "dashboard",
  //   icon: "mdi mdi-home",
  //   permissions: permissions.NONE.permissions,
  //   child: false,
  //   url: "/",
  // },
  // {
  //   labelText: "Counter",
  //   routeName: "cashier",
  //   icon: "mdi mdi-calculator",
  //   permissions: permissions.NONE.permissions,
  //   child: false,
  //   url: "/cashier",
  // },
  // {
  //   labelText: "Tasks",
  //   routeName: "task-new",
  //   icon: "mdi mdi-format-list-checks",
  //   permissions: permissions.NONE.permissions,
  //   child: false,
  //   url: "/all-tasks"    
  // },

  {
    labelText: "Issue Route Permits",
    routeName: "issue_route_permit",
    icon: "fa fa-home",
    permissions: permissions.NONE.permissions,
    child: false,
    url: "/",
  },
  {
    labelText: "View Route Permits",
    routeName: "view-permits",
    icon: "fa fa-list",
    permissions: permissions.NONE.permissions,
    child: false,
    url: "/view-permits",
  },

  {
    labelText: "Reports",
    routeName: "reportvrp",
    icon: "fa fa-file",
    permissions: permissions.NONE.permissions,
    child: false,
    url: "/report-vrp",
  },
  // {
  //   labelText: "Setting",
  //   routeName: "setting",
  //   icon: "mdi mdi-settings",
  //   permissions: permissions.NONE.permissions,
  //   child: false,
  //   url: "/"    
  //   // nested: [
  //   //   {
  //   //     labelText: "Create Tasks",
  //   //     routeName: "task.allTasks",
  //   //     icon: "mdi mdi-playlist-check",
  //   //     url: "/",
  //   //     child: false,
  //   //     permissions: permissions.NONE.permissions,
  //   //   },
  //   //   {
  //   //     labelText: "Edit Tasks",
  //   //     routeName: "task.ad-hoc-inspection",
  //   //     icon: "mdi mdi-playlist-edit",
  //   //     url: "/test2",
  //   //     child: false,
  //   //     permissions: permissions.NONE.permissions,
  //   //   },
  //   // ],
  // },
  // {
  //   labelText: "Vehicle",
  //   routeName: "addVehicle",
  //   icon: "fas fa-car",
  //   permissions: permissions.NONE.permissions,
  //   child: true,
  //   nested: [
  //     {
  //       labelText: "Add Vehicle",
  //       routeName: "profile.user",
  //       icon: "fas fa-bus",
  //       url: "/add-vehicle",
  //       child: false,
  //       permissions: permissions.NONE.permissions,
  //     }, {
  //       labelText: "Approval Request",
  //       routeName: "profile.role",
  //       icon: "mdi mdi-account-details",
  //       url: "/approval-request",
  //       child: false,
  //       permissions: permissions.NONE.permissions,
  //     }
  //   ]
  // },
];

export { siteMenu };
