/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-07 10:36:29 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-07 15:22:22
 */
import React from "react";

import {defaultTemplateType} from "../../../config/template.config";
import {TemplateTwoSideMenu} from "./template-two-includes/TemplateTwoSideMenu";

import {TemplateTwoBodyWrapper} from "./template-two-includes/TemplateTwoBodyWrapper";
import { PageLoader } from "./common-includes/PageLoader";

 const TemplateTwo=({
    templateTypes=defaultTemplateType,
    children=null,
    pageTitle="",
    breadCrumbList=[]  
 })=>{
     return (
        <div className={`templateTwoWrapper ${templateTypes}TemplateTwoWrapper`}>
            <PageLoader />
           <TemplateTwoSideMenu/>
            <TemplateTwoBodyWrapper
                children={children}
                pageTitle={pageTitle}
                breadCrumbList={breadCrumbList}
            />
        </div>
     )
 }

 export {
    TemplateTwo
 }