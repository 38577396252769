/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-02-28 17:31:46 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-17 10:12:43
 */

import React, { Fragment } from 'react';


const DashboardRoutes = () => {
    return (
        <Fragment>

        </Fragment>
    )
};

export default DashboardRoutes;
