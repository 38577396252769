/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 15:02:30
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-07-01 11:40:53
 */

import {
  mockApiBaseURL,
  vehicleBffBaseURL,
  authServerURL,
  tempReportUrl,
  baseUrl,
} from "./core.config";

export const paginAPI = {
  url: `${mockApiBaseURL}api/master/dummies/paging`,
  key: "paginResultKEY",
};

export const vehicleAPI = {
  url: `${vehicleBffBaseURL}/vrp-service/vehicle`,
  key: "vehicleApiKey",
}

export const vehicleLatestRoutePermitAPI = {
  url: `${vehicleBffBaseURL}/vrp-service/vehicle`,
  key: "vehicleLatestRoutePermitApiKey",
}


export const vehicleGetRRoutePermitByIdAPI = {
  url: `${vehicleBffBaseURL}/vrp-service/vehicle`,
  key: "vehicleGetoutePermitByIdApiKey",
}

export const vehicleCreateRoutePermitAPI = {
  url: `${vehicleBffBaseURL}/vrp-service/vehicle`,
  key: "vehicleCreateRoutePermitApiKey",
}

export const vehicleRoutePermitSearchApi = {
  url: `${vehicleBffBaseURL}/vrp-service/vehicle/route-permit`,
  key: "vehicleSearchRoutePermitApi",
}

export const userAuthServiceAPI = {
  url: `${authServerURL}/auth`,
  key: "userAuthServiceAPIKey",
};

export const userInfoAPI = {
  url: `${vehicleBffBaseURL}/vrp-service/user-login`,
  key: "userInfoAPIKEY"
}

export const refreshTokenAPI = {
  url: `${vehicleBffBaseURL}/vrp-service/token-refresh`,
  key: "refreshTokenAPIKey",
};

export const userLogoutAPI = {
  // url:`${adminBffBaseURL}auth/realms/erl-internal-user/protocol/openid-connect/logout`,
  url: `${vehicleBffBaseURL}/vrp-service/logout`,
  key: "userLogoutAPIKEY"
}


export const userPasswordChangeAPI = {
  // url:`${adminBffBaseURL}auth/realms/erl-internal-user/protocol/openid-connect/logout`,
  url: `${vehicleBffBaseURL}/vrp-service/password-update`,
  key: "userLogoutAPIKEY"
}

export const reportGenerateAPI = {
  url: `${vehicleBffBaseURL}/report-service/report`,
  key: "generateReportAPIKEY"
}

//temporaryh url
export const reportServiceGenerateAPI = {
  url: `${tempReportUrl}/api/report-service/v1/report`,
  key: "generateReportAPIKEY"
}