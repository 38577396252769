/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-10 14:01:54
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-05-06 15:56:33
 */


const baseUrl = process.env.REACT_APP_BASE_URL;

const mockApiBaseURL = "http://localhost:4000/";

const authTokenStorageKey = "@webVrpTes1AuthKey";

const vehicleBffBaseURL = process.env.REACT_APP_ERL_VEHICLE_BFF_BASE_URL;

const authServiceClient = process.env.REACT_APP_AUTH_SERVICE_CLIENT;

const authServerURL = process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL;

const authServiceClientSecret = process.env.REACT_APP_AUTH_SERVICE_CLIENT_SECRET;

const tempReportUrl = process.env.REACT_APP_TEMP_REPORT_URL;

const ReactAppVerNO = "V0.11.26";

const uploadComponentConfig = {
    txt: "file-txt.png",
    pdf: "file-pdf.png",
    docx: "file-word.png",
    xlsx: "file-excel.png",
};

const validImageTypes = {
    png: "image/png",
    jpg: "image/jpg",
    jpeg: "image/jpeg",
};

const validFileTypes = {
    txt: "text/plain",
    pdf: "application/pdf",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};



export {
    mockApiBaseURL,
    authTokenStorageKey,
    baseUrl,
    vehicleBffBaseURL,
    authServiceClient,
    authServiceClientSecret,
    uploadComponentConfig,
    validImageTypes,
    validFileTypes,
    ReactAppVerNO,
    authServerURL,
    tempReportUrl
}
