
/*
 * @Author: Chamith Jayathilaka 
 * @Date: 2022-02-12 23:23:00 
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-02-12 23:23:00 
 */

 /**
 * Validates And returns route Permit Status as Expired or Other
 * @param {string} rawStatus original status from backend 
 * @param {Date} expiryDate expiry Date
 * @returns {string} status
 */

const getRoutePermitStatus=(rawStatus,expiryDate)=>{
    let status = rawStatus;
    if( new Date(expiryDate) < new Date()){
      status='Expired'
    }
    return status
  }

export {
  getRoutePermitStatus
}