/*
 * @Author: Farah.J
 * @Date: 2020-09-29
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-15 16:15:45
 */

import React, { Fragment ,useContext } from "react";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { DataTable, actionBtnTypes, RawIndexMemo } from "../../../ui-components/ui-elements/table/DataTable";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { _getInt, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { ViewPermitDetails } from "./includes/ViewPermit";
import { SearchElement } from "./includes/SearchElement";
import { EditPermit } from "./includes/EditPermit";
import { vehicleRoutePermitSearchApi } from "../../../../config/apiUrl.config";
import { SubmitButton } from "../../../ui-components/ui-elements/form/Button";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { vehicleGetRRoutePermitByIdAPI } from "../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { apiResponseStoringType } from "../../../ui-components/ui-elements/table/DataTable";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { getRoutePermitStatus } from '../../../../helpers/common-helpers/getRoutePermitStatus.helper'

const emptyFn = (...para) => undefined;
const RowNo = (props) => {
  return (
    <RawIndexMemo
      page_size={_getInt(props.pagingObject, "per_page", 1)}
      current_page={_getInt(props.pagingObject, "current_page", 1)}
      index={props.rowIndex || 0}
    />
  );
}

const editPermitFormKey = "editPermit";



const UpdateButton = ({ onClosePopupFn = emptyFn }) => {
  const [formState, formAction] = useContext(FormContext);
  return (
    <Fragment>
      <SubmitButton
        btnText="Update"
        elementStyle="btnWrapper"
        formGroupName={editPermitFormKey}
        startIcon="fas fa-save"
        isBtnDisabled={((_get(formState[editPermitFormKey], "unchangeStatus", "") == 'Active' &&  _get(formState[editPermitFormKey], "status", "") == 'Active') || (_get(formState[editPermitFormKey], "unchangeStatus", "") == 'Inactive' &&  _get(formState[editPermitFormKey], "status", "") == 'Inactive')) || getRoutePermitStatus( _get(formState[editPermitFormKey], "status", ""),_get(formState[editPermitFormKey], "expiryDate", "")) == 'Expired'  &&  _get(formState[editPermitFormKey], "status", "") != 'Inactive' }
        dataTableKey={vehicleRoutePermitSearchApi.key}
        isValidate={_get(formState[editPermitFormKey], "status", "") == 'Inactive'}
        flashMessages={{
          "success": {
            status: true,
            message: "Route Permit updated successfully",
            messageType: "success"
          }
        }}
        mapValidationErrorObj={{
            prefix: "updated_reason",
            name: "Route Permit already exists"
        }}
        validationObject={{
          fileds: {
            updated_reason: "Reason for Inactive",
          },
          rules: {
            updated_reason: "required|max:200",
          },
          message: {
            updated_reason: "Reason for Inactive is Required",
          }
        }}
        onGetAPIEndPointFn={(formState) => {
            return {
              url: `${vehicleGetRRoutePermitByIdAPI.url}/${formState.vehicle_id}/route-permit/${formState.id}`,
              key: vehicleGetRRoutePermitByIdAPI.key
            };
        }}
        onChangeRequestBodyFn={({...formObject}) => {
            formObject.id = undefined;
            let reason =formObject.updated_reason
            if(formObject.status==='Active'){
              reason =null
            }
            return getDataByFormObject({
              ...formObject,
              updated_reason:reason,
              status: formObject.status,
            });
        }}
        callApiObject={{
            isSetHeaders: true,
            method: "put",
            multipart: false,
            onUpload: false
        }}
        apiDataStoringObject={{
            setLoader: true,
            storingType: apiResponseStoringType.dateTableLinkedForm,
            mergeToSuccessResponse: null,
            mergeToErrorResponse: null
        }}
        onResponseCallBackFn={(response, error) => {
            if (response.length === undefined && error === undefined && response.data.errors === undefined) {
                onClosePopupFn();
            }
        }}
      />
    </Fragment>
  );
}

const EditPermitElement = (props) => {
  return <EditPermit formGroupName={editPermitFormKey} data={props} />;
}

const ViewPermits = () => {
  const [authStatus, authAction] = useContext(AuthContext);
  return (
    <DashboardTemplate
      pageTitle="View Route Permits"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "View Route Permits" },
      ]}
    >
      <div className="fullWidthDiv">
        <DataTable
          tableName="View Permits"
          dataTableKey={vehicleRoutePermitSearchApi.key}
          apiUrl={`${vehicleRoutePermitSearchApi.url}?&mode=VRP%20latest&sort_by=issued_at|desc`}
          isSetSearchFrom={true}
          defaultSearchFormObject={{
            status:"",
            vehicle_reg_no: undefined,
            issued_from: null,
            issued_to: null,
            searchBy:1,
            route_permit_no: undefined,
          }}
          onSetSearchObjectFn={(searchObject) => {
            let tempSearchObject = {...searchObject};
       
            if (isEmptyValue(searchObject.status)) {
                tempSearchObject["status"] = null;
            }

            if(searchObject.searchBy ==1){
              tempSearchObject["route_permit_no"] =null
            }else{
              tempSearchObject["vehicle_reg_no"] =null
            }

            if (!isEmptyValue(searchObject.issued_from)) {
              tempSearchObject["issued_from"] = dateObjectToString(new Date(searchObject.issued_from));
            }

            if (!isEmptyValue(searchObject.issued_to)) {
              tempSearchObject["issued_to"] = dateObjectToString(new Date(searchObject.issued_to));
            }

            return tempSearchObject;
        }}
          searchFromComponent={
            <SearchElement dataTableKey={vehicleRoutePermitSearchApi.key} />
          }
          tableHeaderList={[
            { displayName: "No.", key: "__no__", isSort: false },
            { displayName: "Vehicle ID", key: "vehicle_reg_no", isSort: false },
            { displayName: "Route Permit No.", key: "route_permit_no", isSort: false },
            { displayName: "Status", key: "status", isSort: false,
            onPrintFn: (rawData)=>{
              return (getRoutePermitStatus(rawData.status,rawData.expiry_date))
            }
          },
            { displayName: "Issued Date", key: "issuance_date", isSort: false,
            onPrintFn: (rawData)=>{
              return (dateObjectToString(rawData.issued_at, "dd/mm/YY"))
            } },
            { displayName: "Expiry Date",
             key: "expiry_date", 
             isSort: false ,
             onPrintFn: (rawData)=>{
              return (dateObjectToString(rawData.expiry_date, "dd/mm/YY"))
            }
           },
          ]}
          isSetAction={true}
          actionTypeList={[
       
            {
              actionType: actionBtnTypes.view,
              actionBtn: { icon: "fas fa-binoculars", tooltip: 'View' },
              modelContentElement: ViewPermitDetails,
              dialogPopupProps: {
                isFullScreen: false,
                isSaveButton: false,
                isCloseButton: true,
                dialogTitle: "View Permit",
              },
            },
            {
              actionType: actionBtnTypes.edit,
              actionBtn: { icon: 'fas fa-edit', tooltip: 'Edit' },
              modelContentElement: EditPermitElement,
              dialogPopupProps: {
                isFullScreen: false,
                isCloseButton: true,
                dialogTitle: "Edit Permit",
                isSaveButton: true,
                isCustomButton: true,
                isConfirmationPopup: true,
                customButtonComponent: UpdateButton
              },
              checkBeforeActionRenderFn: (rawData) => {
                return (getRoutePermitStatus(rawData.row.status,rawData.row.expiry_date) !='Expired');
              }
            }
          ]}
          specificComponents={{
            "__no__": RowNo
          }}
        />
      </div>
    </DashboardTemplate>
  );
};

export default ViewPermits;;
