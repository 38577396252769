import React, {  useState,useContext,useEffect } from "react";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import {
  FormWrapper,
  InputBoxWithState,
  SubmitButton,
  DatePickerWithState
} from "../../../ui-components/ui-elements/form";
import { _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { vehicleCreateRoutePermitAPI } from "../../../../config/apiUrl.config";
import { Filter } from "./includes/Filter";
import {NewRoutePermitInfo} from './includes/NewRoutePermitInfo'
import {IssuedPermitDetails} from "./includes/IssuedPermitDetails"
import { getDataByFormObject } from "../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { UIContext } from "../../../ui-components/context-providers/UIContext.provider";
import { vehicleLatestRoutePermitAPI } from "../../../../config/apiUrl.config";
import { callApi } from "../../../../helpers/common-helpers/callApi.helpers";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { getRoutePermitStatus } from '../../../../helpers/common-helpers/getRoutePermitStatus.helper'

const IssueRoutePermit = (props) => {
  return (
    <DashboardTemplate
      pageTitle="Issue Route Permit"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home", icon: "fas fa-home" },
        { urlTo: "/issue_route_permit", displayName: " Issue Route Permit" },
      ]}
    >
      <IssueRoutePermitDetail props={{ ...props }} />
    </DashboardTemplate>
  )
};

const IssueRoutePermitDetail = () => {
  const formGroupName="vrpsearch"
  let errorMessage ='Route Permit Creation Failed'
  const [getState, setState] = useState({
    "vehicle_reg_no": "",
    "chassis_no": "",
    "vehicleObject":'',
    "routePermitObject":null,
    "currentObject": null,
  })
  const [, uiAction] = useContext(UIContext);
  const [noRecordsMessage,setNoRecordsMessage]= useState('Vehicle does not exist. Please contact the relevant PDMT')
  const [formState, formAction] = useContext(FormContext);

  

/**
* @description: calls route permit get api and sets the state
* @param {Number} vehicledId vehicleId for search
* @param {Object} vehicleData new Vehicled data if exists to set new vehicle State
*/
const getLatestRoutePermitandUpdateState=(vehicledId,vehicleData=null,isReset=false)=>{
  // if no vehicle Id exist
  if(!vehicledId){
    setState({
      ...getState,
      searchKey:'no_route_permit',
      routePermitObject: null,
      vehicleObject :isReset ? '':null,
  })
  return
  }

  // used when route permit gets invalidated in update screen
  if(isReset){
    setState({
      ...getState,
      searchKey:'no_route_permit',
      routePermitObject: null,
      vehicleObject :'',
      vehicle_reg_no: "",
      chassis_no: ""
     })
  formAction.changeInputFn('issuePermitSearch_search', "vehicle_reg_no", '')
  formAction.changeInputFn('issuePermitSearch_search', "chassis_no", '')
  return
  }
  callApi(`${vehicleLatestRoutePermitAPI.url}/${vehicledId}/route-permit/latest`)
 .headers(true)
 .method("get")
 .send((err, result) => {
     if (result?._statue ===true) {
      setState({
        ...getState,
         searchKey:'route_id_found',
         routePermitObject: {
        routePermitId:result.data.data.id,
         routePermitNo: result.data.data.route_permit_no,
         status: result.data.data.status,
         issuedDate: result.data.data.issued_at,
         expiryDate: result.data.data.expiry_date,
       },
       vehicleObject:vehicleData !=null ? vehicleData:{...getState.vehicleObject}  
   })
     } else {
            setState({
              ...getState,
              searchKey:'no_route_permit',
              routePermitObject: null,
              vehicleObject:vehicleData !=null ? vehicleData:{...getState.vehicleObject}
          })
         }
 })
}

/**
* @description: returns the expiry date currently 1 year from current date and remove a day
*/
const getExpiryDate=()=>{
  // get today and substract 1 day
  let today = new Date();
  today.setDate(today.getDate()-1);
  return new Date(today.setFullYear(new Date().getFullYear() + 1))
}


  return (
    <div className="rpWrapper">
      <Filter
        getState={getState}
        setState={setState}
        setNoRecordsMessage={setNoRecordsMessage}
        getLatestRoutePermit={getLatestRoutePermitandUpdateState}
      />

      {
        ((getState.vehicleObject === '') ? (null) : (getState.vehicleObject === null) ? (<div className="col-md-12 defaultPaddingTop floatLeft">{noRecordsMessage}</div>) : ((getState.routePermitObject === null) ? (
          <FormWrapper
            setGroupName={formGroupName}
            elementStyle={"issuePermitWrapper"}
          >
            <div className="col-md-12 floatLeft">
              <UICard
                cardHeading="Issue Permit "
                passPropsToCardHeadingTail={{
                  uiType: "owner_infor",
                  formGroupName: formGroupName,
                  routePermitInfo: _get(getState.vehicleObject, `routePermitInfo`, {})
                }}

              >
                <NewRoutePermitInfo
                    getState={getState} 
                />
                <br />
                        <FormWrapper
         setGroupName={formGroupName}
         setFormObject= {{
          expiry_date:getExpiryDate()
         }}
        >
                <div className="row">
                  <div className="col-md-12  ">
                    <h1 className="defaultHeading defaultMarginBottom">Route Permit Information</h1>
                    <div className="row">
                      <div className="col-md-6  ">
                        <InputBoxWithState
                          labelText={"Route Permit No."}
                          formGroupName={formGroupName}
                          isRequired={true}
                          inputName={"route_permit_no"}
                          isDisabled={false}
                          maxLength={20}
                        />
                      </div>
                      <div className="col-md-6  ">
                        <DatePickerWithState
                          labelText={"Expiry Date"}
                          formGroupName={formGroupName}
                          isRequired={true}
                          inputName={"expiry_date"}
                          inputValue={getExpiryDate()}
                          format={'dd/MM/yyyy'}
                          isDisabled={false}
                          maxDate={getExpiryDate()}
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <SubmitButton
                  elementWrappercStyle={"floatRight"}
                  btnName={"Submit"}
                  formGroupName={formGroupName}
                  tooltip={"Submit"}
                  btnText={"Submit"}
                  isValidate= {true}
                  flashMessages={{
                    "success": {
                      status: true,
                      message: "Route Permit added successfully",
                      messageType: "success"
                    }
                  }}
                  validationObject={{
                    fileds: {
                      route_permit_no: "Route Permit No.",
                      expiry_date: 'Expiry Date'
                    },
                    rules: {
                      route_permit_no: "required|alphaNumeric",
                      expiry_date: "required",
                    },
                    message: {}
                  }}
                  onGetAPIEndPointFn={(formState) => {
                    return {
                      url: `${vehicleCreateRoutePermitAPI.url}/${getState.vehicleObject.vehicleId}/route-permit`,
                      key: vehicleCreateRoutePermitAPI.key
                    }
                  }}
                  callApiObject={{
                    setLoader: true,
                    method: "post",
                  }}
                  mapValidationErrorObj={{
                    route_permit_no: `Route Permit number already exists against Vehicle`,
                }}
                  onChangeRequestBodyFn={({...formObject}) => {
                    formObject.id = undefined;
                    let tempexpiryDate = getExpiryDate();
                    if(formObject.expiry_date){
                      tempexpiryDate = formObject.expiry_date;
                    }
                    return getDataByFormObject({
                      ...formObject,
                      issued_at: dateObjectToString(new Date(), "YY-mm-dd HH:MM:II"),
                      expiry_date:  dateObjectToString(tempexpiryDate, "YY-mm-dd"),
                      status:"Active"
                    });
                }}
                  apiDataStoringObject={{
                    setLoader: true,
                    storingType: "API_RESPONSE_LINKED_FORM",
                    mergeToSuccessResponse: null,
                    mergeToErrorResponse: null
                  }}
                  onResponseCallBackFn={(error, response) => {
                 
                      // if(error?.data?.errors?.details){
                      //   errorMessage =error?.data?.errors?.details[0].message
                      //   uiAction.setFlashMessage({
                      //     status: true,
                      //     message: error?.data?.errors?.details[0].message,
                      //     messageType: "error"
                      // });
                      // }else if(response?._statue===true) {
                      //   getLatestRoutePermitandUpdateState(getState.vehicleObject.vehicleId,null,true)
                      // }
                      if(response?._statue===true) {
                      getLatestRoutePermitandUpdateState(getState.vehicleObject.vehicleId,null,true)
                       }
                  }}
                />
</FormWrapper>
             
              </UICard>
            </div>

          </FormWrapper>
        ) : (
            <IssuedPermitDetails 
            getState={getState}
            setState={setState}
            getLatestRoutePermitandUpdateState={getLatestRoutePermitandUpdateState}
            />

          )))
      }
    </div>
  );

  
};




export  {
  IssueRoutePermit
};
