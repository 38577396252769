/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Material Date Picker
 * @Date: 2020-03-02 16:52:42
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-05 15:07:03
 */

import React, {  memo } from 'react';
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker,KeyboardTimePicker } from '@material-ui/pickers';

import {defaultTemplateType, templateTypes,formCacheLevel} from "../../../../config/template.config";
import { FormControlWrapper } from "./form-includes/FormCoreIncludes";
import { FormHelperTextWrapper } from "./form-includes/FormCoreIncludes";
import {useBasicInput} from "../../../hooks/common-hooks/useFormInputState.hook";


const emptyFun = (...para) => undefined;

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker
 * --------------------------------------------
 */

// DatePicker variants
const datePickerVariants = {
    dialog: "dialog",
    inline: "inline",
    static: "static"
};

// DatePicker formats
const datePickerFormats = {
    "yyyy-MM-dd": "yyyy-MM-dd",
    "dd-MM-yyyy": "dd-MM-yyyy",
    "yyyy/MM/dd": "yyyy/MM/dd",
    "dd/MM/yyyy": "dd/MM/yyyy",
    "MM/dd/yyyy": "MM/dd/yyyy",
    "MM-dd-yyyy": "MM-dd-yyyy"
};

// DateTimePicker formats
const dateTimePickerFormats = {
    "yyyy-MM-dd": "yyyy-MM-dd hh:mm a",
    "dd-MM-yyyy": "dd-MM-yyyy hh:mm a",
    "yyyy/MM/dd": "yyyy/MM/dd hh:mm a",
    "dd/MM/yyyy": "dd/MM/yyyy hh:mm a",
    "MM/dd/yyyy": "MM/dd/yyyy hh:mm a",
    "MM-dd-yyyy": "MM-dd-yyyy hh:mm a"
};

const DatePicker = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    isDisabled = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    isAllowedKeyboardInputs = false,
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['yyyy-MM-dd'],
    onChangeFn = emptyFun,
    minDate = "1970-01-01",
    maxDate = "2500-01-01",
    isRequired=false,
    InputLabelProps={ shrink: true }
}) => {
    return (
        <FormControlWrapper
            elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
            isFullWidth={isFullWidth}
            inputError={inputError}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardDatePicker
                    className={`${uiType}DatePickerStyle ${elementStyle} ${(inputError !== "") ? "datePickerError" : ""}`}
                    disabled={isDisabled}
                    id={inputName}
                    fullWidth={isFullWidth}
                    variant={variant}
                    format={format}
                    margin="normal"
                    label={labelText}
                    value={inputValue}
                    required={isRequired}
                    onChange={(date) => onChangeFn({ name: inputName, value: date })}
                    minDate={minDate}
                    maxDate={maxDate}
                    InputLabelProps={InputLabelProps}
                    InputProps={{
                        readOnly: !isAllowedKeyboardInputs,
                    }}
                />

                {/* <FormHelperTextWrapper
                    inputError={inputError}
                    helperText={helperText}
                /> */}

            </MuiPickersUtilsProvider>

        </FormControlWrapper>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker
 * --------------------------------------------
 */

DatePicker.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        datePickerFormats["yyyy-MM-dd"],
        datePickerFormats["dd-MM-yyyy"],
        datePickerFormats["dd/MM/yyyy"],
        datePickerFormats["yyyy/MM/dd"],
        datePickerFormats["MM/dd/yyyy"],
        datePickerFormats["MM-dd-yyyy"]
    ]),
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DatePicker---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker
 * --------------------------------------------
 */

const DateTimePicker = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    isDisabled = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = dateTimePickerFormats['yyyy-MM-dd'],
    onChangeFn = emptyFun,
    minDate = "1970-01-01",
    maxDate = "2200-01-01"
}) => {
    return (
        <FormControlWrapper
            elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
            isFullWidth={isFullWidth}
            inputError={inputError}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardDateTimePicker
                    className={`${uiType}DatePickerStyle ${elementStyle} ${(inputError !== "") ? "datePickerError" : ""}`}
                    disableToolbar={isDisabled}
                    id={inputName}
                    fullWidth={isFullWidth}
                    variant={variant}
                    format={format}
                    margin="normal"
                    label={labelText}
                    value={inputValue}
                    onChange={(date) => onChangeFn({ name: inputName, value: date })}
                    minDate={minDate}
                    maxDate={maxDate}
                />

                <FormHelperTextWrapper
                    inputError={inputError}
                    helperText={helperText}
                />

            </MuiPickersUtilsProvider>

        </FormControlWrapper>
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker
 * --------------------------------------------
 */

DateTimePicker.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        dateTimePickerFormats["yyyy-MM-dd"],
        dateTimePickerFormats["dd-MM-yyyy"],
        dateTimePickerFormats["dd/MM/yyyy"],
        dateTimePickerFormats["yyyy/MM/dd"],
        dateTimePickerFormats["MM/dd/yyyy"],
        dateTimePickerFormats["MM-dd-yyyy"]
    ]),
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DateTimePicker---------------------

//----------------TimePicker---------------------
const TimePicker = ({
    uiType = defaultTemplateType,
    elementWrapperStyle = "",
    elementStyle = "",
    isDisabled = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['yyyy-MM-dd'],
    onChangeFn = emptyFun
}) => {
    return (
        <FormControlWrapper
            elementWrapperStyle={`${uiType}InputWrapper ${elementWrapperStyle}`}
            isFullWidth={isFullWidth}
            inputError={inputError}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                <KeyboardTimePicker
                    className={`${uiType}DatePickerStyle ${elementStyle} ${(inputError !== "") ? "datePickerError" : ""}`}
                    disableToolbar={isDisabled}
                    id={inputName}
                    fullWidth={isFullWidth}
                    variant={variant}
                    margin="normal"
                    label={labelText}
                    value={inputValue}
                    onChange={(date) => onChangeFn({ name: inputName, value: date })}

                />

                <FormHelperTextWrapper
                    inputError={inputError}
                    helperText={helperText}
                />

            </MuiPickersUtilsProvider>

        </FormControlWrapper>
    );
};
//----------------TimePicker---------------------

/**
 * memo render 
 * @param {Object} prevProps 
 * @param {Object} nextProps 
 */
const areEqual=(prevProps, nextProps) =>{
    if(nextProps.cacheLevel===formCacheLevel.none){
      return false;
    }else if(nextProps.cacheLevel===formCacheLevel.updateOnFormGroupChange){
      return (
        (prevProps.uiType===nextProps.uiType) && 
        (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
        (prevProps.labelText===nextProps.labelText) && 
        (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
        (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
        (prevProps.inputValue===nextProps.inputValue) && (prevProps.updateStatus===nextProps.updateStatus)
      )
    }else if(nextProps.cacheLevel===formCacheLevel.updateOnIndividual){
      return (
        (prevProps.uiType===nextProps.uiType) && 
        (prevProps.elementStyle===nextProps.elementStyle) && (prevProps.isFullWidth===nextProps.isFullWidth) &&
        (prevProps.labelText===nextProps.labelText) && 
        (prevProps.inputError===nextProps.inputError) && (prevProps.helperText===nextProps.helperText) &&
        (prevProps.isDisabled===nextProps.isDisabled) && (prevProps.isRequired===nextProps.isRequired) &&
        (prevProps.inputValue===nextProps.inputValue)
      )
    }
    
  };
  
  const DatePickerMemo = memo(DatePicker, areEqual);
  const DateTimePickerMemo = memo(DateTimePicker, areEqual);
  const TimePickerMemo = memo(TimePicker, areEqual);

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker With State
 * --------------------------------------------
 */

const DatePickerWithState = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    isDisabled = false,
    isFullWidth = true,
    isAllowedKeyboardInputs = false,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['yyyy-MM-dd'],
    formGroupName = "",
    inputStatePath = "",
    cacheLevel=formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun,
    minDate = "1970-01-01",
    maxDate = "2200-01-01",
    isRequired =false
}) => {

    const [currentValue,currentError,updateStatus,handleOnChangeFn]=useBasicInput(inputStatePath,formGroupName,inputName,inputValue,onChangeFn);

    return (
        <DatePickerMemo 
            uiType={uiType}
            elementStyle={elementStyle}
            isDisabled={isDisabled}
            isFullWidth={isFullWidth}
            inputName={inputName}
            inputValue={currentValue}
            isAllowedKeyboardInputs={isAllowedKeyboardInputs}
            labelText={labelText}
            inputError={currentError}
            helperText={helperText}
            variant={variant}
            format={format}
            onChangeFn={(eventData => {
                handleOnChangeFn(eventData);
            })}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
            minDate={minDate}
            maxDate={maxDate}
            isRequired ={isRequired}
        />
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DatePicker With State
 * --------------------------------------------
 */

DatePickerWithState.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        datePickerFormats["yyyy-MM-dd"],
        datePickerFormats["dd-MM-yyyy"],
        datePickerFormats["dd/MM/yyyy"],
        datePickerFormats["yyyy/MM/dd"],
        datePickerFormats["MM/dd/yyyy"],
        datePickerFormats["MM-dd-yyyy"]
    ]),
    /** Form group name */
    formGroupName: PropTypes.string,
    /** Input element state avilable path(use for manage complex objects) */
    inputStatePath: PropTypes.string,
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DatePickerWithState---------------------

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker With State
 * --------------------------------------------
 */

const DateTimePickerWithState = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    isDisabled = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = dateTimePickerFormats['yyyy-MM-dd'],
    formGroupName = "",
    inputStatePath = "",
    cacheLevel = formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun,
    minDate = "",
    maxDate = ""
}) => {
    const [currentValue,currentError,updateStatus,handleOnChangeFn] = useBasicInput(inputStatePath,formGroupName,inputName,inputValue,onChangeFn);

    return (
        <DateTimePickerMemo
            uiType={uiType}
            elementStyle={elementStyle}
            isDisabled={isDisabled}
            isFullWidth={isFullWidth}
            inputName={inputName}
            inputValue={currentValue}
            labelText={labelText}
            inputError={currentError}
            helperText={helperText}
            variant={variant}
            format={format}
            onChangeFn={(eventData => {
                handleOnChangeFn(eventData);
            })}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
            minDate={minDate}
            maxDate={maxDate}
        />
    );
};

/**
 * --------------------------------------------
 * @Author: Chanaka Wickramasinghe
 * @Description: DateTimePicker With State
 * --------------------------------------------
 */

DateTimePickerWithState.propTypes = {
    /** DatePicker type */
    uiType: PropTypes.oneOf([templateTypes.material, templateTypes.bootstrap]),
    /** DatePicker element css class */
    elementStyle: PropTypes.string,
    /** Is disable toolbar */
    isDisableToolbar: PropTypes.bool,
    /** Is full width true or false */
    isFullWidth: PropTypes.bool,
    /** Input name */
    inputName: PropTypes.string,
    /** Input value */
    inputValue: PropTypes.object,
    /** Label text */
    labelText: PropTypes.string,
    /** Input error */
    inputError: PropTypes.string,
    /** Helper text */
    helperText: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([datePickerVariants.dialog, datePickerVariants.inline, datePickerVariants.static]),
    /** Formats */
    format: PropTypes.oneOf([
        dateTimePickerFormats["yyyy-MM-dd"],
        dateTimePickerFormats["dd-MM-yyyy"],
        dateTimePickerFormats["dd/MM/yyyy"],
        dateTimePickerFormats["yyyy/MM/dd"],
        dateTimePickerFormats["MM/dd/yyyy"],
        dateTimePickerFormats["MM-dd-yyyy"]
    ]),
    /** Form group name */
    formGroupName: PropTypes.string,
    /** Input element state avilable path(use for manage complex objects) */
    inputStatePath: PropTypes.string,
    /** Onchange function */
    onChangeFn: PropTypes.func
};

//----------------DateTimePickerWithState---------------------

//----------------TimePickerWithState---------------------

const TimePickerWithState = ({
    uiType = defaultTemplateType,
    elementStyle = "",
    isDisabled = false,
    isFullWidth = true,
    inputName = "",
    inputValue = new Date(),
    labelText = "",
    inputError = "",
    helperText = "",
    variant = datePickerVariants.dialog,
    format = datePickerFormats['yyyy-MM-dd'],
    formGroupName = "",
    inputStatePath = "",
    cacheLevel=formCacheLevel.updateOnFormGroupChange,
    onChangeFn = emptyFun,
    minDate = "1970-01-01",
    maxDate = "2200-01-01"
}) => {

    const [currentValue,currentError,updateStatus,handleOnChangeFn]=useBasicInput(inputStatePath,formGroupName,inputName,inputValue,onChangeFn);

    return (
        <TimePickerMemo 
            uiType={uiType}
            elementStyle={elementStyle}
            isDisabled={isDisabled}
            isFullWidth={isFullWidth}
            inputName={inputName}
            inputValue={currentValue}
            labelText={labelText}
            inputError={currentError}
            helperText={helperText}
            variant={variant}
            format={format}
            onChangeFn={(eventData => {
                handleOnChangeFn(eventData);
            })}
            updateStatus={updateStatus}
            cacheLevel={cacheLevel}
            minDate={minDate}
            maxDate={maxDate}
        />
    );
};


export {
    DatePicker,
    datePickerVariants,
    datePickerFormats,
    DatePickerWithState,
    DatePickerMemo,
    formCacheLevel,
    dateTimePickerFormats,
    DateTimePicker,
    DateTimePickerWithState,
    DateTimePickerMemo,
    TimePicker,
    TimePickerMemo,
    TimePickerWithState
};
