/*
 * @Author: Pradeepa Sandaruwan
 * @Date:  2021-01-15 12:51:24
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-19 09:54:30
 */
import React ,{ useState } from 'react';
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { useContext } from 'react';
import { UICard ,Icon } from '../../../../ui-components/ui-elements/common/BaseElements';
import { FormWrapper, InputBoxWithState, InputButton,SubmitButton } from '../../../../ui-components/ui-elements/form';
import { vehicleAPI,vehicleLatestRoutePermitAPI } from "../../../../../config/apiUrl.config";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { callApi } from "../../../../../helpers/common-helpers/callApi.helpers";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { apiResponseStoringType } from "../../../../ui-components/ui-elements/table/DataTable";

const emptyFn = (...para) => undefined



const Filter = ({
    getState = {},

    setState = emptyFn,
    getLatestRoutePermit =emptyFn,
    setNoRecordsMessage =emptyFn,
    formGroupName = "issuePermitSearch_search",
    searchFormKey="issuePermitSearch",
}) => {
    const [isShowPopup, showPopup] = useState(false);
    const [formState, formAction] = useContext(FormContext);
    const toggleshowChassisPopup = () => showPopup(!isShowPopup);

    return (
  
        <div className="topWrapper">
            <div className="searchWrapper">
                <FormWrapper elementStyle={""} 
                setGroupName={formGroupName} 
                setFormObject={{vehicle_reg_no:getState.vehicle_reg_no,
                    chassis_no:getState.chassis_no}}>
                    <UICard
                        elementStyle="defaultPadding"
                    >
                        <label className="defaultPaddingRight ">Vehicle ID <sup className="redAsterix"> *</sup></label>

                        <InputBoxWithState
                            isFullWidth={false}
                            elementStyle={"marginNone "}
                            formGroupName={formGroupName}
                            labelText={""}
                            inputName={"vehicle_reg_no"}
                            inputValue={getState.vehicle_reg_no}
                            maxLength={8}
                        />

                        <label className="defaultPaddingLeftRight">Last Six Characters of the Chassis Number<sup className="redAsterix"> *</sup></label>

                        <InputBoxWithState
                            isFullWidth={false}
                            inputName={"chassis_no"}
                            elementStyle={"marginNone defaultPaddingLeftRight"}
                            formGroupName={formGroupName}
                            labelText={""}
                            maxLength={6}

                        />
                                <Icon
                    iconClass={"mdi mdi-help"}
                 elementStyle={"chassisNohelp"}
                 onClickFn={() =>{
                    toggleshowChassisPopup()
                }}
                 tooltip={"Chassis Number"}
                    />
                <SubmitButton
                isFullWidth={false}
                elementWrapperStyle={"defaultMarginLeftRight defaultPaddingLeftRight"}
                formGroupName={formGroupName}
                tooltip={"Search"}
                btnName="search"
                isValidate= {true}
                validationObject={{
                  fileds: {
                    vehicle_reg_no: "Vehicle ID",
                    chassis_no: "Last Six Characters of the Chassis Number",
                  },
                  rules: {
                    vehicle_reg_no: "required",
                    chassis_no: "required",
                  },
                  message: {}
                }}
                mapValidationErrorObj={{
                    vehicle_reg_no: "Vehicle registration number must be a valid vehicle number",
                }}
                onGetAPIEndPointFn={(formState) => {
                  return {
                    url: `${vehicleAPI.url}?vehicle_reg_no=${formState.vehicle_reg_no}&chassis_no=${formState.chassis_no}`,
                    key: vehicleAPI.key
                  }
                }}
                callApiObject={{
                  isSetHeaders: true,
                  method: "get",
                }}
                apiDataStoringObject={{
                  setLoader: true,
                  storingType: "API_RESPONSE_LINKED_FORM",
                  mergeToSuccessResponse: null,
                  mergeToErrorResponse: null
                }}
                onResponseCallBackFn={(error, response) => {
                  if (response?._statue ===true) {
    
                         let vehicleData={
                                vehicleId:  response.data.data.vehicle_no,
                                name: response.data.data.vehicle_no,
                                chassisNumber: response.data.data.chassis_no,
                                currentRL: response.data.data.current_revenue_licence?.licence_number,
                                rlExpiryDate:  response.data.data.current_revenue_licence?.valid_to,
                                class: response.data.data.vehicle_class?.name,
                                owner: response.data.data.owner_id?.name,
                                address: response.data.data.owner_id?.address_line_1
                         }
                        getLatestRoutePermit(response.data.data.vehicle_no,vehicleData)
  
                 }else if(response === undefined){
                     if(error?.data?.errors?.details[0].message ==='This vehicle does not require a vehicle route permit.'){
                        setNoRecordsMessage(error?.data?.errors?.details[0].message)
                     }else{
                        setNoRecordsMessage('Vehicle does not exist. Please contact the relevant PDMT')
                     }
                     if(error?.data?.errors?.name ==="Bad Request"){
                        getLatestRoutePermit(null,undefined , true)
                     }else{
                        getLatestRoutePermit()
                     } 
             
                 }
                }
            }
                overrideTextToIcon={
                    <i className="mdi mdi-magnify-plus-outline" />
                }
              />

                        <InputButton
                            btnName="refresh"
                            elementStyle={"PaddingNone"}
                            tooltip="Reset"
                            onClickBtnFn={(value) => {
                                formAction.changeInputFn(formGroupName, "vehicle_reg_no", '')
                                formAction.changeInputFn(formGroupName, "chassis_no", '')
                                formAction.setFormErrorFn(formGroupName)
                                getLatestRoutePermit(null,null,true)
                            }

                            }
                            overrideTextToIcon={
                                <i className="mdi mdi-refresh" />
                            }
                        />
                    </UICard>
                </FormWrapper>

                
            <DialogPopup
                dialogTitle={"How to find the Chassis No ?"}
                isFullScreen={false}
                isOpen={isShowPopup}
                onClosePopupFn={() =>{
                    toggleshowChassisPopup()
                }}
                isConfirmationPopup={false}
            >

                <div>
                    <span class="chasisNoDescription">You can find the Chassis Number in the Vehicle Registration Book/Form</span>
        
                    <img className="chassisNoHelpImage" 
                    alt={'chasisNoHelpImage'}
                    src={"images/findchasisNoHelp4.png"}>
                    </img>
                  
                    </div> 
            </DialogPopup>
            </div>
        </div>
    );
}

export {
    Filter
};