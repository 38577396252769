/*
 * @Author: Chamith Jayathilaka
 * @Date: 2022-01-31
 */

import React, { useState, useContext } from "react";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SearchElement } from "./reports-includes/SearchElement";
import { reportGenerateAPI ,reportServiceGenerateAPI } from "../../../../config/apiUrl.config";
import {
  _get,
} from "../../../../helpers/common-helpers/lodash.wrappers";
import { templateTypes } from "../../../../config/template.config";
import { FormContext } from "../../core/context-providers/FormContext.provider";
import { FormWrapper, SelectBoxWithState, SubmitButton } from "../../../ui-components/ui-elements/form";
import { downloadFile } from "../../../../helpers/download-helpers/download.helpers"

const ReportVrp = () => {
  const searchformGroupName = `vrp_report_filter`;
  const exportformGroupName = `export_report_form`;
  const reportName = "Vrp Report"
  const reportCode = 'fr11-vrp-issue-route-permit-entire'

  const [htmlTemplate, sethtmlTemplate] = useState(null);
  const [reportgeneratedValues, setReportgeneratedValues] = useState({});
  const [formState, formAction] = useContext(FormContext);


  const onGenerateSubmit = (event) => {
    sethtmlTemplate({ __html: event });
  };

  const onExportToPdfSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.pdf`);
  };

  const onExportToExcelSubmit = (event) => {
    downloadFile(new Blob([event]), `${reportName}.xlsx`);
  };

  const onSetGeneratedValues = (event) => {
    setReportgeneratedValues(event)
  };

  const onResetClick = () =>{
    sethtmlTemplate(null)
    setReportgeneratedValues({})
  }

  return (
    <DashboardTemplate
      pageTitle="Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Reports" },
      ]}
    >
      <UICard>
        <div class="report-heading">
          <h5>Reports</h5>
        </div>

        <SearchElement
          reportCode={reportCode}
          formGroupName={searchformGroupName}
          onGenerateSubmit={onGenerateSubmit}
          setReportgeneratedValues={onSetGeneratedValues}
          onResetClick={onResetClick}
        ></SearchElement>
      </UICard>

      {htmlTemplate && (
        <div class="mt-2 report-html-view">
          <UICard>
            <div dangerouslySetInnerHTML={htmlTemplate} />
          </UICard>
        </div>
      )}


      {htmlTemplate && (
        <div class="mt-2">
          <UICard>
            <FormWrapper
              setGroupName={exportformGroupName}
              formGroupLinkWith={exportformGroupName}
              onDestroyUnsetFormObject={true}
              setFormObject={{
                export_to: null
              }}
            >
              <div className={"reportExportToAlignWrapper"}>
                {/* <div className="exporTypeSelectDropdown">
                  <SelectBoxWithState
                    uiType={templateTypes.bootstrap}
                    formGroupName={exportformGroupName}
                    emptySelectOptionTxt={'  '}
                    inputValue={null}
                    dataList={[
                      {
                        id: "xlsx",
                        value: "Excel",
                      },
                      {
                        id: "pdf",
                        value: "PDF",
                      }
                    ]}
                    inputName={"export_to"}
                    labelText="Export To"
                  />

                </div> */}
                <div className="exportBtn">
                  {/* <SubmitButton
                    btnText={"Export"}
                    elementStyle="btnWrapper"
                    formGroupName={exportformGroupName}
                    isValidate={true}
                    validationObject={{
                      fileds: {
                        export_to: "Export To"
                      },
                      rules: {
                        export_to: "required"
                      },
                      message: {}
                    }}
                    callApiObject={{
                      isSetHeaders: true,
                      method: "get",
                      multipart: false,
                      onUpload: false,
                      responseType: 'arraybuffer'
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: "API_RESPONSE_LINKED_FORM",
                      mergeToSuccessResponse: true,
                      mergeToErrorResponse: true
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=${formState?.export_to}&report_limit=${reportgeneratedValues?.limit}&report_offset=${reportgeneratedValues?.offset}&report_requestFromDate=${reportgeneratedValues?.date_from}&report_requestToDate=${reportgeneratedValues?.date_to}&report_requestStatus=${reportgeneratedValues?.status}&report_generatedUser=${reportgeneratedValues?.reportGeneratedUser}`,
                        key: `${reportGenerateAPI.key}`
                      }
                    }}

                    onResponseCallBackFn={(error, response,) => {
                      if (response?._statue) {
                        if (_get(formState[exportformGroupName], "export_to", "") === 'pdf') {
                          onExportToPdfSubmit(response?.data)
                        }
                        if (_get(formState[exportformGroupName], "export_to", "") === 'xlsx') {
                          onExportToExcelSubmit(response?.data)
                        }
                      }
                    }}
                    
                  /> */}

<SubmitButton
                    btnText={"Download"}
                    elementStyle="btnWrapper"
                    formGroupName={exportformGroupName}
                    isValidate={true}
  
                    callApiObject={{
                      isSetHeaders: true,
                      method: "get",
                      multipart: false,
                      onUpload: false,
                      responseType: 'arraybuffer'
                    }}
                    apiDataStoringObject={{
                      setLoader: true,
                      storingType: "API_RESPONSE_LINKED_FORM",
                      mergeToSuccessResponse: true,
                      mergeToErrorResponse: true
                    }}
                    onGetAPIEndPointFn={(formState) => {
                      return {
                        url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=pdf&report_limit=${reportgeneratedValues?.limit}&report_offset=${reportgeneratedValues?.offset}&report_requestFromDate=${reportgeneratedValues?.date_from}&report_requestToDate=${reportgeneratedValues?.date_to}&report_requestStatus=${reportgeneratedValues?.status}&report_generatedUser=${reportgeneratedValues?.reportGeneratedUser}`,
                        key: `${reportGenerateAPI.key}`
                      }
                    }}

                    onResponseCallBackFn={(error, response,) => {
                      if (response?._statue) {
                          onExportToPdfSubmit(response?.data)
                      }
                    }}
                  />
                </div>
              </div>
            </FormWrapper>
          </UICard>
        </div>
      )}
    </DashboardTemplate>
  );
};
export default ReportVrp;

