import React, { Fragment, useState ,useContext } from 'react';
import { templateTypes } from '../../../../../config/template.config';
import { SnackBarList } from '../../../../ui-components/templates/common-includes/SnackbarWrapper';
import { Icon, UICard } from '../../../../ui-components/ui-elements/common/BaseElements';
import { FormWrapper, InputBoxWithState, InputButtonWithState,SubmitButton,InputButton ,RoundButton} from '../../../../ui-components/ui-elements/form';
import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {PageLoader} from "../../../../ui-components/templates/common-includes/PageLoader";
import { DialogPopup } from "../../../../ui-components/ui-elements/modal/DialogPopup";
import { AuthContext } from "../../../core/context-providers/AuthContext.provider";
import { userPasswordChangeAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { Link, useHistory } from 'react-router-dom';
const loginFromKey="loginForm";



const ChangePassword=(props)=>{
    const history = useHistory();
    const formKey = "changePassword";
    const [isPasswordShow, setPasswordShow] = useState(false);
    const [isConfirmPasswordShow, setConfirtmPasswordShow] = useState(false);
    const [isShowPopup, showPopup] = useState(false);
    const [authState] = useContext(AuthContext);

    const togglePasswordVisibility = () => setPasswordShow(!isPasswordShow);
    const toggleConfirmPasswordVisibility = () => setConfirtmPasswordShow(!isConfirmPasswordShow);
    const toggleshowPolicy = () => showPopup(!isShowPopup);

    return(
        <div className="LoginMainWrapper">
            <UICard elementStyle="loginCard">
                <Fragment>
                    <div className="loginHeaderWrapper">
                         
                        <h4 className="defaultMarginTop mb-1">Change Password</h4>
                    </div>
                    <FormWrapper
                        elementStyle="loginFormWrapper"
                        setGroupName={formKey}
                    >

                        <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"current_password"}
                            labelText={"Current Password"}
                            isRequired={true}
                            inputType={"password"}
                            maxLength={20}
                        />
             
                                <div className="passwordWrapper">
                                <InputBoxWithState
                            uiType={templateTypes.bootstrap}
                            formGroupName={formKey}
                            inputName={"new_password"}
                            labelText={"New Password"}
                            isRequired={true}
                            inputType={isPasswordShow ? "text":"password"}
                            maxLength={20}
                        />
                                                    <Icon
                            onClickFn={togglePasswordVisibility}
                            iconClass={isPasswordShow ? "mdi mdi-eye":"mdi mdi-eye-off"}
                            elementStyle={"eye"}
                            />
                                                    <Icon
                            onClickFn={toggleshowPolicy}
                            iconClass={"mdi mdi-help"}
                            elementStyle={"help"}
                            />
                                </div>

                        <div className="passwordWrapper">
                            <InputBoxWithState
                                uiType={templateTypes.bootstrap}
                                formGroupName={formKey}
                                inputType={isConfirmPasswordShow ? "text":"password"}
                                inputName={"confirmation_password"}
                                labelText="Confirm Password"
                                isRequired={true}
                                maxLength={20}
                            />

                            <Icon
                            onClickFn={toggleConfirmPasswordVisibility}
                            iconClass={isConfirmPasswordShow ? "mdi mdi-eye":"mdi mdi-eye-off"}
                            elementStyle={"eye"}
                            />
                        </div>

                        

                        <div className="displayBlock floatRight">
                        <InputButton
                    elementWrapperStyle={"defaultHalfMarginLeftRight"}
                    btnText="Cancel"
                    onClickBtnFn={() =>{
                        history.push("/");
                    } }
                  />
                        <SubmitButton
                                elementWrapperStyle={"floatRight"}
                                btnText={"Submit"}
                                elementStyle="btnWrapper"
                                startIcon={"far fa-save"}
                                formGroupName={formKey}
                                isValidate={true}
                                flashMessages={{
                                    "success": {
                                        status: true,
                                        message: "Password Successfully changed",
                                        messageType: "success"
                                    }
                                }}
                                validationObject={{
                                    fileds: {
                                        current_password: "Current Password",
                                        new_password: "New Password",
                                        confirmation_password: "Confirm Password"
                                    },
                                    rules: {
                                        current_password: "required",
                                        new_password: "required",
                                        confirmation_password: "required|same:new_password"
                                    },
                                    message: {}
                                }}
                                mapValidationErrorObj={{
                                    current_password: "Invalid password",
                                }}
                                callApiObject={{
                                    isSetHeaders: true,
                                    method: "put",
                                    multipart: false,
                                    onUpload: false
                                }}
                                apiDataStoringObject={{
                                    setLoader: true,
                                    storingType: "API_RESPONSE_LINKED_FORM",
                                    mergeToSuccessResponse: true,
                                    mergeToErrorResponse: true
                                }}
                                onGetAPIEndPointFn={(formObject) => {
                                    return {
                                        url: `${userPasswordChangeAPI.url}/${_get(authState, "authUser.id")}`,
                                        key: userPasswordChangeAPI.key
                                    }
                                }}
                                onChangeRequestBodyFn={(formObject) => {
                                    return getDataByFormObject(formObject);
                                }}
                                onResponseCallBackFn={(error) => {
                                    if (!error) {
                                        props.history.push("/");
                                    }
                                }}
                            />
                        </div>
                    </FormWrapper>
                </Fragment>
            </UICard>

            <DialogPopup
                dialogTitle={"Password Policy"}
                isFullScreen={false}
                isOpen={isShowPopup}
                onClosePopupFn={() =>{
                    toggleshowPolicy()
                }}
                isConfirmationPopup={false}
            >

                <div>
                    <span class="policyDescription">Password Policy should follow the following requirements</span>
                 
                    <div className="mt-3">
              
                    <div className="policyItem">  Password should be at least 8 characters long. </div>
                    <div className="policyItem">Should be a combination of alphanumeric (A-Z, a-z, 0-9). </div>
                    <div className="policyItem">Should include at least one special character. </div>
           
                    </div>
                  
                    </div> 
            </DialogPopup>
            <PageLoader/>
        </div>
    )
}

export {
    ChangePassword
}