
const authUser={
  "userName":"inova",
  "password":"inova@123"
}


const normalLicenseDummy = {
  };


const dealerLicenseDummy = {
  
}

const routePermitDummy = {
  "ca-1234":{
    routePermitInfo: {
      name: "ca-1234",
      chassisNumber: "1ADKJ89BNTY20887",
      currentRL: "6572539",
      rpNumber: "990023",
      issuedDate:"01/12/2020",
      rlExpiryDate: "01/12/2021",
      status: "Active",
    },
  },

  "kp-5454":{
    routePermitInfo: {
      name: "kp-5454",
      chassisNumber: "9FTKJ89BJLY20664",
      currentRL: "6572539",
      rlExpiryDate: "01/12/2021",
      class: "MOTOR TRICYCLE",
      owner: "R.M.L JAYASUNDARA",
      address: "NO:5,SULEIMAN TERRACE,COLOMBO 05.",
    },
  },
};

const notification=[
  
];

export {
    normalLicenseDummy,
    dealerLicenseDummy,
    authUser,
    routePermitDummy,
    notification
}