/*
 * @Author: Chamith Jayathilaka
 * @Date: 2022-01-11 10:30:00
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time: 2022-01-11 10:30:00
 */


const reportGenerationMaxRangeMonths = 1;

const reportDataLimit = 1000;
const reportDataOffset = 0;

const vrpReport = {
    name: `Vrp Report`,
    code: "",
};
export {
    reportDataLimit,
    reportGenerationMaxRangeMonths,
    reportDataOffset
};