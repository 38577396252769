/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-07 13:47:09
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-04-06 13:32:39
 */

import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { MenuItemWrapper, MenuWrapper, } from '../../ui-elements/lists-and-menus/ListsAndMenus';
import { Icon, UICard } from '../../ui-elements/common/BaseElements';
import { DialogPopup } from '../../ui-elements/modal/DialogPopup';
import { FormWrapper, SubmitButton, } from '../../ui-elements/form/index';
import {  userInfoAPI, userLogoutAPI } from "../../../../config/apiUrl.config";
import { _find, _findindex, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../modules/core/context-providers/FormContext.provider";
import { apiResponseStoringType } from "../../../../config/apiResponseKey";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { authTokenStorageKey, ReactAppVerNO } from "../../../../config/core.config";
import { callApi } from '../../../../helpers/common-helpers/callApi.helpers';
import { setToLocalStorage } from '../../../../helpers/common-helpers/manageStorage.helpers';
import { getDataByFormObject } from '../../../../helpers/common-helpers/common.helpers';

const emptyFn = (...para) => undefined;



const NotificationWrapper = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [getState, setState] = useState({
    delegationModelStatus: false,
  });
  const history = useHistory();
  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  const [getDelegation, setDelegation] = useState(null);
  const [coreState, coreAction] = useContext(CoreContext);

  const setDeligationValue = (id) => {
    setDelegation(id)
    formAction.changeInputFn("switchDelegation", "switchDelegation", "", id)
  }

  const logoutApiCall = () => {
    const requestBody = {
      //client_id:_get(authStatus, 'keycloak.clientId', null),
      refresh_token: _get(authStatus, 'keycloak.refreshToken', null)
    }

    coreAction.sendRequestFn(userLogoutAPI.url)
      .isMultipart(false)
      .method("post")
      .body(requestBody)
      .setLoader(true)
      .setInitStoring("none", null)
      .headers(true)
      .send((error, result) => {
        if (!error) {
          authAction.unauthorisedUserFn();
        }
      });

  }

  const profileMenuFn = (key) => {

    if (key === 'delegation') {
      setState({
        ...getState,
        delegationModelStatus: true,
      });
    } else if (key === 'logout') {
      // authAction.unauthorisedUserFn();
      //authStatus.keycloak.logout();
      logoutApiCall();
    } else if (key === 'notification') {
      history.push('/notification');
    } else if (key === 'changePassword') {
      history.push('/change-password');
    }

    setAnchorEl(undefined);
  };

  const switchDeligationError = () => {
    const check = _find(_get(formState, `switchDelegation._errors`, []), (e) => e.property === "switchDelegation");
    if (check !== undefined) {
      return {
        status: true,
        message: check.message
      };
    } else {
      return {
        status: false
      }
    }
  };

  return (
    <div className={`notificationNaviBar`}>
      <ul className={'notificationUL'}>
      <li>
                    <strong>{_get(authStatus, 'authUser.name', "")} ({_get(authStatus, 'authUser.vrp_center_name', "")})
            
                    </strong>
                </li>

        <li aria-controls="profileMenu" aria-haspopup="true">
          <span onClick={(event) => setAnchorEl(event.currentTarget)} 	style={{ display: 'inline-block' }}>

            <i className="mdi mdi-account mainIcon"></i>
            &nbsp;
            <i className="mdi mdi-chevron-down arraow"></i>
        
          </span>

          <MenuWrapper
            id="profileMenu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onCloseFn={() => profileMenuFn(null)}
            elementStyle={'hedaerMenus profileMenu'}
          >


            <MenuItemWrapper
              elementStyle={'profileMenuItem'}
              menuKey="changePassword"
              onClickFn={profileMenuFn}
              onCloseFn={() => profileMenuFn(null)}
            >
              <p>
                <Icon iconClass="fas fa-user-edit" />
                Change Password
              </p>
            </MenuItemWrapper>

            <MenuItemWrapper
              elementStyle={'profileMenuItem'}
              menuKey="logout"
              onClickFn={profileMenuFn}
              onCloseFn={() => profileMenuFn(null)}
            >
              <p>
                <Icon iconClass="mdi mdi-logout" />
                Logout
              </p>
            </MenuItemWrapper>
            <MenuItemWrapper
              elementStyle={'profileMenuItem'}
              menuKey="logout"
              onCloseFn={() => profileMenuFn(null)}
            >
              <p>
                {`Version ${ReactAppVerNO}`}
              </p>

            </MenuItemWrapper>
          </MenuWrapper>
        </li>
      </ul>

      <DialogPopup
        isOpen={getState.delegationModelStatus}
        dialogTitle={'Switch Delegation'}
        onClosePopupFn={() =>
          setState({
            ...getState,
            delegationModelStatus: !getState.delegationModelStatus,
          })
        }
        isCloseButton={true}
        isCustomButton={true}
        customButtonComponentProps={
          {
            delegation_id: getDelegation,
            user_id: _get(authStatus, 'authUser.id', "")
          }
        }
      >
        <UICard>
          <FormWrapper
            // elementStyle="col-md-12"
            setGroupName={'switchDelegation'}
            onRebuildResponseFn={(response) => {
              const index = _findindex(response, (e) => {
                return e.id === _get(authStatus, 'authUser.current_delegation', "")
              })
              const current_delegation = response.splice(index, 1);
              return {
                "current_delegation": current_delegation[0],
                "delegations": response,
                "_onLoad": false,
              }
            }}
          >
            <div className="row">
              <div className="col-md-12 defaultMarginBottom">
                <dl className="row">
                  <dt className="col-sm-6 text-right">Current Delegation</dt>
                  <dd className="col-sm-6">{_get(formState, 'switchDelegation.current_delegation.role.name', "")}
                    {_get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', null) ?
                      (` - ${_get(formState, 'switchDelegation.current_delegation.orgData.pdmt[0].code', '')}`) :
                      (` - ${_get(formState, 'switchDelegation.current_delegation.orgData.authority[0].pdmt[0].code', '')}`)
                    }</dd>
                </dl>

              </div>


              <div className="col-md-12 defaultHalfMarginTop">
                {
                  (switchDeligationError().status === true) ? (
                    <div className={"defaultFormHelperText text-danger"}>
                      {switchDeligationError().message}
                    </div>
                  ) : null
                }

                <UICard
                  cardHeading="Switch To"
                  inputName={"switchDelegation"}
                >
                  <React.Fragment>
                    {
                      _get(formState, 'switchDelegation.delegations', []).map(delegation => (
                        <div className="col-md-12" key={delegation.id}>
                          <p onClick={() => { setDeligationValue(_get(delegation, 'id', "")) }} style={{ cursor: "pointer" }}>
                            {
                              (getDelegation === _get(delegation, 'id', "") ? (
                                <i className="mdi mdi-check mainIcon" />
                              ) : null)
                            }
                            {_get(delegation, 'role.name', "")} {(_get(delegation, 'orgData.pdmt[0].code', null) ?
                              ` - ${_get(delegation, 'orgData.pdmt[0].service.name', '-')}` : ` - ${_get(delegation, 'orgData.authority[0].pdmt[0].service.name', '-')}`)}
                          </p>
                        </div>
                      ))
                    }
                  </React.Fragment>
                </UICard>

              </div>
            </div>
          </FormWrapper>
        </UICard>
      </DialogPopup>
    </div>
  );
};

const HeaderWrapper = ({
  fixedMenuStatus = true,
  pageTitle = '',
  breadCrumbList = [],
}) => {
  return (
    <div
      className={`headerWrapper ${fixedMenuStatus === true ? 'halfHeader' : 'fullHeader'
        }`}
    >
      <div className={`breadcrumbWrapper`}>
        <div className="headerTxtWrapper">
          <h1 className="pageHeadingTxt">{pageTitle}</h1>
        </div>
        <div className="pageLinks">
          {breadCrumbList.length !== 0 ? (
            <span className="otherLinks">
              <i className="mdi mdi-home"></i>
              <span className="separator">/</span>
            </span>
          ) : null}
          {breadCrumbList.map((item, index) => {
            if (index === breadCrumbList.length - 1) {
              return (
                <span key={index} className="lastLink">
                  {item.displayName}
                </span>
              );
            } else {
              return (
                <Link
                  key={index}
                  to={item.urlTo}
                  className={`defaultLinkClass`}
                >
                  <span key={index} className="otherLinks">
                    {item.displayName}
                    <span className="separator">/</span>
                  </span>
                </Link>
              );
            }
          })}
        </div>
      </div>

      <NotificationWrapper />
    </div>
  );
};

export { HeaderWrapper };
