/*
 * @Author: Anjula Karunarathne 
 * @Date: 2021-01-15 15:22:21 
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-18 13:47:54
 */

import React, { useContext } from "react";
import {
    FormWrapper,
    SelectBoxWithState,
    InputBoxWithState,
    DatePickerWithState,
} from "../../../../ui-components/ui-elements/form";
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { FormContext } from "../../../core/context-providers/FormContext.provider";

const SearchElement = ({ dataTableKey = "permits" }) => {
    const formGroupName = `${dataTableKey}_search`;
    const [formState, formAction] = useContext(FormContext);



    return (
        <FormWrapper 
        setGroupName={formGroupName}
        formGroupLinkWith={formGroupName}
        onDestroyUnsetFormObject={true}
        >
            <div className="row">
                <div className="col-md-2 floatLeft">
                    <SelectBoxWithState
                        formGroupName={formGroupName}
                        isEnableAllOption={false}
                        dataList={[
                            {
                                id: "1",
                                value: "Vehicle ID",
                            },
                            {
                                id: "2",
                                value: "Route Permit Number",
                            },
                        ]}
                        inputName={"searchBy"}
                        labelText="Search by"
                    />
                </div>

                {_get(formState[formGroupName], "searchBy", "") == '1' &&
               <div className="col-md-2 col-sm-12 floatLeft">
               <InputBoxWithState
                   formGroupName={formGroupName}
                   maxLength="20"
                   labelText="Vehicle ID"
                   inputName={"vehicle_reg_no"}
               />
           </div>
                }

            {_get(formState[formGroupName], "searchBy", "") == '2' &&
               <div className="col-md-2 col-sm-12 floatLeft">
                    <InputBoxWithState
                        formGroupName={formGroupName}
                        labelText="Route Permit Number"
                        inputName={"route_permit_no"}
                    />
           </div>
                }
  

                <div className="col-md-2 col-sm-12 floatLeft">
                    <DatePickerWithState
                        labelText={"Issued From"}
                        formGroupName={formGroupName}
                        inputName={"issued_from"}
                        format={'dd/MM/yyyy'}
                    />
                </div>
                <div className="col-md-2 col-sm-12 floatLeft">
                    <DatePickerWithState
                        labelText={"Issued To"}
                        formGroupName={formGroupName}
                        minDate={_get(formState[formGroupName], "issued_from", null)}
                        inputName={"issued_to"}
                        format={'dd/MM/yyyy'}
                    />
                </div>
                <div className="col-md-2 col-sm-12 floatLeft">
                    <SelectBoxWithState
                        formGroupName={formGroupName}
                        emptySelectOptionTxt={"All"}
                        dataList={[
                            {
                                id: "Active",
                                value: "Active",
                            },
                            {
                                id: "Inactive",
                                value: "Inactive",
                            },
                            {
                                id: "Expired",
                                value: "Expired",
                            }
                        ]}
                        inputName={"status"}
                        labelText="Status"
                        inputValue={1}
                    />
                </div>
            </div>
        </FormWrapper>
    );
};


export { SearchElement };
