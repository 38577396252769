import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import guest from '../../hoc-middlewares/Guest';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import Login from './pages/login/Login';

const AuthRoutes = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/login" component={guest(Login)} routerPermissions={permissions.NONE.permissions} />
        </Fragment>
    )
};

export default AuthRoutes;