import React  from "react";
import { useContext } from 'react';
import { templateTypes } from "../../../../../config/template.config";
import { FormWrapper, DatePickerWithState, SelectBoxWithState } from "../../../../ui-components/ui-elements/form/index";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';

const SearchElement = ({ dataTableKey }) => {
  const formGroupName = `${dataTableKey}_search`;
  const [formState, formAction] = useContext(FormContext);

  const setMinDateFn = (date) => {
    if(date){
      let minDate = new Date(date);
      minDate = new Date(minDate.setDate(minDate.getDate() + 1));
      return minDate;
    }else{
      return  new Date(1970,1,1);
    }
  };

  const setMaxDateFn = (date) => {
    if(date){
      let maxDate = new Date(date);
      maxDate = new Date(maxDate.setDate(maxDate.getDate()));
      return maxDate;
    }else{
      return  new Date(2200,1,1);
    }
  };
  return (
    <FormWrapper
    setGroupName={formGroupName}
    formGroupLinkWith={formGroupName}
    onDestroyUnsetFormObject={true}
    >
      <div className={"row"}>
        <div className="col-md-4 col-sm-12 floatLeft">
          <DatePickerWithState
            formGroupName={formGroupName}
            inputName={"issued_from"}
            labelText={"Issued From"}
            format={'dd/MM/yyyy'}
            maxDate={setMaxDateFn(_get(formState[formGroupName], "dateTo", null))}
          />
        </div>
        <div className="col-md-4 col-sm-12 floatLeft">
          <DatePickerWithState
            // elementStyle="MuiInputLabel-animated MuiInputLabel-shrink"
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            inputName={"dateTo"}
            labelText={"Issued To"}
            format={'dd/MM/yyyy'}
            minDate={setMinDateFn(_get(formState[formGroupName], "issued_from", null))}
          />
        </div>
        <div className="col-md-4 col-sm-12 floatLeft ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            emptySelectOptionTxt={"select status"}
            dataList={[
              {
                id: "Active",
                value: "Active",
              },
              {
                id: "Inactive",
                value: "Inactive",
              },
              {
                id: "Expired",
                value: "Expired",
              }
            ]}
            inputName={"status"}
            labelText="Status"
          />
        </div>
      </div>
    </FormWrapper>
  );
};
export { SearchElement };