import React,{Fragment,useContext} from "react"
import {ReissuePermit} from "./ReissuePermit"
import {EditRoutePermit} from "./EditRoutePermit"
import { RoundButtonWithPopups ,SubmitButton } from "../../../../ui-components/ui-elements/form/Button";
import {roundBtnSizes} from "../../../../ui-components/ui-elements/form";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import {_get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { vehicleGetRRoutePermitByIdAPI } from "../../../../../config/apiUrl.config";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { getRoutePermitStatus } from '../../../../../helpers/common-helpers/getRoutePermitStatus.helper'
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { UIContext } from "../../../../ui-components/context-providers/UIContext.provider";


const ReissuecloseBtn = {
  btnText: "Cancel",
  btnName: "close_model",
  startIcon: "far fa-times-circle",
  elementStyle: "buttonSuccess",
  isBtnDisabled: false,
};

const RoutePermitDetailsIcons = (props) => {
    const getEditMode = () => {
        return EditRoutePermit
    };
  
    const getViewMode = () => {
        return ReissuePermit;
    };

    const isExpiredOrExpiresInOneMonth = (date)=>{
      // get date before 1 month
     date.setMonth(date.getMonth()-1);
     if(new Date(date) < new Date()){
       return true
     }else{
      return false
     }
    }


    return (
      <Fragment>
  {getRoutePermitStatus(_get(props?.getState.routePermitObject, "status", ""),_get(props?.getState.routePermitObject, "expiryDate", ""))=='Active' &&
 <RoundButtonWithPopups
 btnName="edit"
 btnText="Edit"
 iconClass={"mdi mdi-pencil"}
 tooltip="Edit"
 btnSize={roundBtnSizes.medium}
 dialogProps={{
   dialogTitle: "Update Permit",
   isConfirmationPopup:true,
   isCustomButton: true,
   setState:props.setState,
   customButtonComponent: UpdateButton,
   customButtonComponentProps: { 
     setState:props.setState,
     getState:props.getState,
     getLatestRoutePermitandUpdateState:props.getLatestRoutePermitandUpdateState,
   },

 }}
 modelComponent={getEditMode()}
 propsToModel={props}
/>}
       
{isExpiredOrExpiresInOneMonth(new Date(_get(props?.getState.routePermitObject, "expiryDate", ""))) &&
        <RoundButtonWithPopups
        btnName="viewmore"
        iconClass={"mdi mdi-play"}
        tooltip="Renew/ Reissue Route Permit"
        //btnSize={roundBtnSizes.large}
        dialogProps={{
          dialogTitle: "Renew/ Reissue Route Permit",
          isConfirmationPopup:true,
          isCustomButton: true,
          setState:props.setState,
          closeButton:ReissuecloseBtn,
          customButtonComponent: ReissueSubmitButton,
          customButtonComponentProps: { 
            setState:props.setState,
            getState:props.getState,
            getLatestRoutePermitandUpdateState:props.getLatestRoutePermitandUpdateState,
          },
        }}

        modelComponent={getViewMode()}
        propsToModel={{...props,closeButton:ReissuecloseBtn}}
      />
}
      </Fragment>
    );
  };

  
  const UpdateButton = (
    props,
    ) => {
    const editPermitFormKey = "editRoutePermit";
    const [formState, formAction] = useContext(FormContext);
    return (
      <Fragment>
        <SubmitButton
          btnText="Update"
          elementStyle="btnWrapper"
          formGroupName={editPermitFormKey}
          startIcon="fas fa-save"
          isValidate={_get(formState[editPermitFormKey], "status", "") == 'Inactive'}
          flashMessages={{
            "success": {
              status: true,
              message: "Route Permit updated successfully",
              messageType: "success"
            }
          }}
          validationObject={{
            fileds: {
              updated_reason: "Reasons for Inactive",
            },
            rules: {
              updated_reason: "required|max:200",
            },
            message: {}
          }}
          onGetAPIEndPointFn={(formState) => {
              return {
                url: `${vehicleGetRRoutePermitByIdAPI.url}/${formState.vehicle_id}/route-permit/${formState.routePermitId}`,
                key: vehicleGetRRoutePermitByIdAPI.key
              };
          }}
          onChangeRequestBodyFn={({...formObject}) => {
              formObject.id = undefined;
              let reason =formObject.updated_reason
              if(formObject.status==='Active'){
                reason =null
              }
              return getDataByFormObject({
                ...formObject,
                updated_reason:reason,
                status: formObject.status,
              });
          }}
          callApiObject={{
              isSetHeaders: true,
              method: "put",
              multipart: false,
              onUpload: false
          }}
          apiDataStoringObject={{
              setLoader: true,
              mergeToSuccessResponse: true,
              mergeToErrorResponse: true
          }}
          onResponseCallBackFn={(error, response) => {
            if(response?._statue===true){
              if(_get(formState[editPermitFormKey], "status", "") === 'Active'){
                props.getLatestRoutePermitandUpdateState(props.getState.vehicleObject.vehicleId)
              }else{
                props.getLatestRoutePermitandUpdateState(props.getState.vehicleObject.vehicleId,null,true)
              }

              props.onClosePopupFn();
            }
  
          }}
        />
      </Fragment>
    );
}


const ReissueSubmitButton = (
  props,
  ) => {
  const reissueFormKey = "reissueRoutePermit";
  const [, uiAction] = useContext(UIContext);
  const [formState, formAction] = useContext(FormContext);
  return (
    <Fragment>
      <SubmitButton
        btnText="Submit"
        elementStyle="btnWrapper"
        formGroupName={reissueFormKey}
        startIcon="fas fa-save"
        isValidate={true}
        flashMessages={{
          "success": {
            status: true,
            message: "Route Permit Reissued successfully",
            messageType: "success"
          }
        }}
        validationObject={{
          fileds: {
            new_rp_no: "Route Permit No.",
          },
          rules: {
            new_rp_no: "required|max:20|alphaNumeric",
          },
          message: {}
        }}
        onGetAPIEndPointFn={(formState) => {
            return {
              url: `${vehicleGetRRoutePermitByIdAPI.url}/${formState.vehicle_id}/route-permit/${formState.routePermitId}/renew`,
              key: vehicleGetRRoutePermitByIdAPI.key
            };
        }}
        onChangeRequestBodyFn={({...formObject}) => {
            formObject.id = undefined;
            return getDataByFormObject({
              ...formObject,
              route_permit_no: formObject.new_rp_no,
              renewed_at: dateObjectToString(new Date(),"YY-mm-dd HH:MM:II"),
              expiry_date:dateObjectToString(new Date(formObject.expiry_date),"YY-mm-dd"),
              status: 'Active',
            });
        }}
        callApiObject={{
            isSetHeaders: true,
            method: "put",
            multipart: false,
            onUpload: false
        }}
        apiDataStoringObject={{
            setLoader: true,
            mergeToSuccessResponse: true,
            mergeToErrorResponse: true
        }}
        onResponseCallBackFn={(error, response) => {
          if(error?.data?.errors?.details){
            let errorMessage =error?.data?.errors?.details[0].message
            uiAction.setFlashMessage({
              status: true,
              message: errorMessage,
              messageType: "error"
          });
        }
          if(response?._statue===true){
            props.getLatestRoutePermitandUpdateState(props.getState.vehicleObject.vehicleId)
            props.onClosePopupFn();
          }
           
        }}
      />
    </Fragment>
  );
}
  export {RoutePermitDetailsIcons}