/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-21 10:00:34 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 13:21:08
 */

import {
    setToggleSideMenuKey,setPageLoaderKey,setRouteKey,
    setFlashMessageKey,removeFlashMessageKey,setSideMenuStatusKey,
    setFixedMenuKey,setToggleSideMenuStatusValueKey,
    setFullScreenKey
} from "../../../config/actionKeys.config";

import {dateObjectToString} from "../../../helpers/common-helpers/dateTime.helpers";


const setFlashMessage=(dispatch,messageObject)=>{

    const key=dateObjectToString(new Date(),"YYmmddHHMMII");
    dispatch({
        type:setFlashMessageKey,
        playload:{
            ...messageObject,
            key:key
        }
    });
    
    setTimeout(()=>{
        removeFlashMessage(dispatch,key);
    }, 5000);
}

const removeFlashMessage=(dispatch,key)=>{
    dispatch({
        type:removeFlashMessageKey,
        key:key
    });
}

const toggleSideMenu=(dispatch)=>{
    dispatch({
        type:setToggleSideMenuKey
    });
}

const toggleFullScreen=(dispatch)=>{
    dispatch({
        type:setFullScreenKey
    });
}

const setToggleSideMenuFn=(dispatch,status)=>{
    dispatch({
        type:setToggleSideMenuStatusValueKey,
        playload:status
    });
}

const setFixedSideMenuFn=(dispatch)=>{
    dispatch({
        type:setFixedMenuKey
    });
}

const setSideToggleStatus=(dispatch,status)=>{
    dispatch({
        type:setSideMenuStatusKey,
        playload:status
    });
}

const setPageLoader=(dispatch,status)=>{
    dispatch({
        type:setPageLoaderKey,
        playload:status
    });
}

const setCurrentRouteFn=(dispatch,key)=>{
    dispatch({
        type:setRouteKey,
        playload:key
    });
}



const uiAction=(dispatch)=>{
    return {
        toggleSideMenu:()=>toggleSideMenu(dispatch),
        setToggleSideMenuFn:(status=false)=>setToggleSideMenuFn(dispatch,status),
        setFixedSideMenuFn:()=>setFixedSideMenuFn(dispatch),
        setSideToggleStatus:(status)=>setSideToggleStatus(dispatch,status),
        setPageLoader:(status)=>setPageLoader(dispatch,status),
        setCurrentRouteFn:(key)=>setCurrentRouteFn(dispatch,key),
        setFlashMessage:(messageObject)=>setFlashMessage(dispatch,messageObject),
        removeFlashMessage:(key)=>removeFlashMessage(dispatch,key),
        toggleFullScreen:()=>toggleFullScreen(dispatch)
    }
}

export {
    uiAction
}