/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 09:25:25
 * @Last Modified by: Jayani Nawarathna
 * @Last Modified time: 2021-09-26 13:02:22
 */

import React, { createContext, useReducer, useContext } from 'react';

import { authAction } from '../core-helpers/authContext.helpers';
import {
  setAuthTokenKey,
  setUnauthorisedUserKey,
  setAuthUserObjectKey,
} from '../../../../config/actionKeys.config';

import { UIContext } from '../../../ui-components/context-providers/UIContext.provider';

const initialState = {
  accessToken: null,
  refreshToken: null,
  keycloakObject: null,
  isAuthenticated: null,
  keycloak: null,
  authUser: {
    id: null,
    name: null,
    userName: null,
    avatar: null,
    organizations: [],
    roles: [],
    permissions: [],
    current_delegation: null,
    service_id: null,
    rolePrefix:null,
    authorityId:null,
    role_level_code:null,
    vrp_company_name: null,
  },
};

const AuthContext = createContext({});

const authReducer = (state, action) => {
  switch (action.type) {
    case setAuthTokenKey:
      return {
        ...state,
        accessToken: action.playload.accessToken,
        refreshToken: action.playload.refreshToken,
        keycloakObject: action.playload.keycloakObject,
        isAuthenticated: true,
        keycloak: action.playload.keycloak,
        authUser: {
          ...state.authUser,
          id: action.playload.id,
          name: action.playload.name,
          userName: action.playload.userName,
          roles: action.playload.role,
          permissions: action.playload.permission,
          current_delegation: action.playload.current_delegation,
          service_id: action.playload.service_id,
          rolePrefix: action.playload.rolePrefix,
          pdmtCode: action.playload.pdmtCode,
          authorityCode: action.playload.authorityCode,
          authorityId:action.playload.authorityId,
          role_level_code:action.playload.role_level_code,
          pdmtId:action.playload.pdmtId,
          vrp_center_name: action.playload.vrp_center_name
        },
      };
    case setUnauthorisedUserKey:
      return {
        ...state,
        ...initialState,
        isAuthenticated: false,
      };
    case setAuthUserObjectKey:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          ...action.playload,
        },
      };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [, uiDispatch] = useContext(UIContext);
  const dispatchFuntion = authAction(dispatch, uiDispatch);
  return (
    <AuthContext.Provider value={[state, dispatchFuntion]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
