/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-09-07 14:02:17
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-10 14:37:22
 */
import React,{useContext,Fragment} from "react";

import {HeaderWrapper} from "./HeaderWrapper";
import {UIContext} from "../../context-providers/UIContext.provider";

const TemplateTwoBodyWrapper = ({
    children=null,
    pageTitle="",
    breadCrumbList=[]  
}) => {
    const [uiStatus]=useContext(UIContext);
  return (
    <Fragment>
      <HeaderWrapper
        pageTitle={pageTitle}
        breadCrumbList={breadCrumbList}
        fixedMenuStatus={uiStatus.fixedMenu}
      />
      <div className={`templateTwoBody ${uiStatus.fixedMenu===true?"":"templateTwoBodyExpand"}`}>
        
        <div className="bodyWrapper">
          {children}
        </div>      
      </div>
    </Fragment>
  );
};


export {
    TemplateTwoBodyWrapper
}