/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-02-28 17:19:46
 * @Last Modified by: Anjula Karunarathne
 * @Last Modified time: 2021-01-15 15:18:28
 */

import React, { Fragment } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

//import routers
import DashboardRoutes from "../components/modules/dashboard/Dashboard.routes";
import ErrorBoundary from "../components/ui-components/ui-elements/common/ErrorBoundary";
import DisplayError from "../components/modules/error-boundary/pages/DisplayError";
import ErrorBoundaryRoutes from "../components/modules/error-boundary/ErrorBoundary.routes";
import AuthRoutes from "../components/modules/auth/Auth.routes";
import IssueRoutePermitRoutes from "../components/modules/issue-route-permit/IssueRoutePermit.routes";
import ViewPermitsRoutes from "../components/modules/view-permits/ViewPermits.routes";
import ChangePasswordRoute from "../components/modules/change-password/ChangePassword.routes";
import ReportRoutes from "../components/modules/reports/report.routes";
import ReportVrpRoutes from "../components/modules/report-vrp/report-vrp.routes"



const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Fragment>
          <ErrorBoundary
            displayComponent={DisplayError}
          >
            <AuthRoutes />
            <DashboardRoutes />
            <ReportRoutes />
            <ReportVrpRoutes />
            <ViewPermitsRoutes />
            <IssueRoutePermitRoutes />
            <ChangePasswordRoute />
            <ErrorBoundaryRoutes />
          </ErrorBoundary>
        </Fragment>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
