/*
 * @Author: Farah.J
 * @Date: 2020-09-29
 */

import React, { useState ,useContext} from "react";
import { DataTable,RawIndexMemo } from "../../../ui-components/ui-elements/table/DataTable";
import { UICard } from "../../../ui-components/ui-elements/common/BaseElements";
import { DashboardTemplate } from "../../../ui-components/templates/DashboardTemplate";
import { SearchElement } from "./reports-includes/SearchElement"
import { _getInt, _get } from "../../../../helpers/common-helpers/lodash.wrappers";
import {SubmitButton,} from "../../../ui-components/ui-elements/form";
import { vehicleRoutePermitSearchApi } from "../../../../config/apiUrl.config";
import { isEmptyValue } from "../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../helpers/common-helpers/dateTime.helpers";
import { AuthContext } from "../../../modules/core/context-providers/AuthContext.provider";

const formKey = "repostsForm"; 
const RowNo = (props) => {
  return (
    <RawIndexMemo
      page_size={_getInt(props.pagingObject, "per_page", 1)}
      current_page={_getInt(props.pagingObject, "current_page", 1)}
      index={props.rowIndex || 0}
    />
  )
}
const Reports = () => {
  const [getState, setState] = useState(false);
  const [authStatus, authAction] = useContext(AuthContext);
  return (
    <DashboardTemplate
      pageTitle="Reports"
      breadCrumbList={[
        { urlTo: "/", displayName: "Home" },
        { urlTo: "", displayName: "Reports" },
      ]}
    >
      <UICard >
        <DataTable
          tableName="Issued Vehicle Route Permits"
          dataTableKey={vehicleRoutePermitSearchApi.key}
          apiUrl={`${vehicleRoutePermitSearchApi.url}?&mode=VRP%20history&sort_by=issued_at|desc&user_id=${_get(authStatus, 'authUser.id', "")}`}
          isSetAction="false"
          isSetSearchFrom={true}
          defaultSearchFormObject={{
            status:null,
            issued_from: null,
            dateTo: null,
          }}
          onSetSearchObjectFn={(searchObject) => {
            let tempSearchObject = {...searchObject};
            // to add issued from mandatory

            
            if (!isEmptyValue(searchObject.issued_from)) {
              tempSearchObject["issued_from"] = dateObjectToString(new Date(searchObject.issued_from));
            }

            if (!isEmptyValue(searchObject.dateTo)) {
              tempSearchObject["dateTo"] = dateObjectToString(new Date(searchObject.dateTo));
            }

            return tempSearchObject;
        }}
          searchFromComponent={<SearchElement dataTableKey={vehicleRoutePermitSearchApi.key} />}
          tableHeaderList={[
            { displayName: "No", key: "__no__", isSort: false },
            { displayName: "Vehicle ID", key: "vehicle_reg_no", isSort: false },
            { displayName: "Route Permit Number", key: "route_permit_no", isSort: false },
            { displayName: "issued date", key: "issued_at", isSort: false,
              onPrintFn: (rawData)=>{
              return (dateObjectToString(rawData.issued_at, "dd/mm/YY"))
            } 
          },
            { displayName: "Expiry Date", key: "expiry_date", isSort: false,
            onPrintFn: (rawData)=>{
              return (dateObjectToString(rawData.expiry_date, "dd/mm/YY"))
            } 
           },
            { displayName: "Status Updated By", key: "updated_by_name", isSort: false },
            { displayName: "Status Updated At", key: "updated_at", isSort: false ,
            onPrintFn: (rawData)=>{
              return (dateObjectToString(rawData.updated_at, "dd/mm/YY HH:MM"))
            } 
          },
            { displayName: "Status", key: "status", isSort: false },
            { displayName: "Reason", key: "updated_reason", isSort: false },
          ]}
          specificComponents={{
            "__no__": RowNo
          }}
        />
        <div className="floatRight defaultPaddingTop">
          <SubmitButton
            formGroupName={formKey}
            btnName={"Download"}
            btnText={"Download"}
          />
        </div>
      </UICard>
    </DashboardTemplate>
  )
};
export default Reports;