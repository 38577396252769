/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-26 20:53:10 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-06-15 10:35:52
 */
import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { siteMenu } from "../../../../../config/menu.config";
import { ListWrapper, ListItemWrapper } from "../../../ui-elements/lists-and-menus/ListsAndMenus";
import { CollapseWrapper, CheckPermission } from "../../../ui-elements/common/BaseElements";
import { UIContext } from "../../../context-providers/UIContext.provider";


const emptyFun = (...para) => undefined;


const SecondMenu = ({
    open = {},
    setOpen = emptyFun,
    menuList = []
}) => {
    const [uiState] = useContext(UIContext);

    return (
        <ListWrapper
            isDisablePadding={true}
            elementStyle={"pl-3"}
        >
            {
                menuList.map((mainMenu, index) => {
                    return (
                        <CheckPermission permission={mainMenu.permissions}>
                            {
                                (mainMenu.child === false) ? (
                                    <Link key={"link" + mainMenu.routeName} to={mainMenu.url} >
                                        <ListItemWrapper
                                            elementStyle={(uiState.currentRouteKey === mainMenu.routeName) ? "firstChildItem menuActive" : "firstChildItem"}
                                            keyValue={mainMenu.routeName}
                                            listItemText={mainMenu.labelText}
                                            leftIcon={mainMenu.icon}
                                        />
                                    </Link>
                                ) : (
                                        <div key={index + mainMenu.routeName}>
                                            <ListItemWrapper
                                                elementStyle={"firstChildItem"}
                                                keyValue={mainMenu.routeName}
                                                listItemText={mainMenu.labelText}
                                                leftIcon={mainMenu.icon}
                                            />
                                            <CollapseWrapper
                                                component={"li"}
                                                isIn={open[mainMenu.routeName]}
                                                isUnMountOnExit={true}
                                            >
                                            </CollapseWrapper>
                                        </div>
                                    )
                            }
                        </CheckPermission>
                    )
                })
            }
        </ListWrapper>
    )
}


const FullMenu = () => {
    const [open, setOpen] = useState({});
    const [uiState] = useContext(UIContext);

    useEffect(() => {
        setOpen({
            [uiState.currentRouteKey.split('.')[0]]: true
        })
    }, []);

    return (
        <ListWrapper>
            {
                siteMenu.map((mainMenu, index) => {
                    return (
                        // <CheckPermission permission={mainMenu.permissions}>
                        <CheckPermission key={index} permission={mainMenu.permissions}>
                            {
                                (mainMenu.child === false) ? (
                                    <Link key={"link" + mainMenu.routeName} to={mainMenu.url} >
                                        <ListItemWrapper
                                            key={mainMenu.routeName}
                                            elementStyle={(uiState.currentRouteKey === mainMenu.routeName) ? "parentItem menuActive" : "parentItem"}
                                            keyValue={mainMenu.routeName}
                                            listItemText={mainMenu.labelText}
                                            leftIcon={mainMenu.icon}
                                        />
                                    </Link>
                                ) : (
                                        <div key={index + mainMenu.routeName}>
                                            <ListItemWrapper
                                                keyValue={mainMenu.routeName}
                                                listItemText={mainMenu.labelText}
                                                leftIcon={mainMenu.icon}
                                                rightIcon={(open[mainMenu.routeName] === true) ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"}
                                                onClickFn={() => setOpen({
                                                    ...open,
                                                    [mainMenu.routeName]: !open[mainMenu.routeName]
                                                })}
                                            />
                                            <CollapseWrapper
                                                component={"li"}
                                                isIn={open[mainMenu.routeName]}
                                                isUnMountOnExit={true}
                                            >
                                                <SecondMenu open={open} setOpen={setOpen} menuList={mainMenu.nested} />
                                            </CollapseWrapper>
                                        </div>
                                    )
                            }
                        </CheckPermission>
                    )
                })
            }
        </ListWrapper>
    );
}



export {
    FullMenu
}
