/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-02-28 18:24:23 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 13:16:34
 */


//FormContext
export const initFormGroupKey="@FormContext/SET_INIT_FORM";
export const removeFormGroupKey="@FormContext/REMOVE_FORM_GROUP";
export const setErrorsKey="@FormContext/SET_INPUT_ERRORS";
export const setInputValueChangeKey="@FormContext/SET_FORM_INPUT_CHANGE";
export const setComplexInputValueChangeKey="@FormContext/SET_COMPLEX_FORM_INPUT_CHANGE";
export const mergeFormObjectKey="@FormContext/MERGE_FROM_ONJECT";


//AuthContext
export const setAuthTokenKey="@AuthContext/SET_AUTH_TOKEN";
export const setUnauthorisedUserKey="@AuthContext/SET_UNAUTHORISED";
export const setAuthUserObjectKey="@AuthContext/SET_AUTH_USER_DATA";

//UIContext
export const setToggleSideMenuKey="@UIContext/SET_TOGGLED_SIDE_MENU_STATUS";
export const setToggleSideMenuStatusValueKey="@UIContext/SET_SIDE_MENU_STATUS";
export const setSideMenuStatusKey="@UIContext/SET_SIDE_MENU_STATUS";
export const setPageLoaderKey="@UIContext/SET_PAGE_LOADER";
export const setRouteKey="@UIContext/SET_CURRENT_ROUTE";
export const setFlashMessageKey="@UIContext/SET_FLASH_MESSAGE";
export const removeFlashMessageKey="@UIContext/REMOVE_FLASH_MESSAGE";
export const setFixedMenuKey="@UIContext/SET_FIXED_OR_NOT";
export const setFullScreenKey="@UIContext/SET_FULL_SCREEN";

//CoreContext
export const initDataTableKey="@CoreContext/INIT_DATA_TABLE_DATA";
export const initResponseKey="@CoreContext/INIT_RESPONSE_DATA";
export const setDataTableKey="@CoreContext/SET_DATA_TABLE_DATA";
export const updateDataTableObjectKey="@CoreContext/UPDATE_DATA_TABLE_CONTENT";
export const shortDataTableDataKey="@CoreContext/SHORT_DATATABLE_DATA";
export const setReloadDataTableKey="@CoreContext/RELOAD_DATA_TABLE";
export const setApiResponseKey="@CoreContext/SET_RESPONSE_DATA";
export const updateDataTableRowKey="@CoreContext/UPDATE_DATA_TABLE_ROW";
export const updateDataTableFieldValueKey="@CoreContext/ON_CHANGE_DATA_TABLE_FIELD_VALUE";
export const setApiResponseErrorKey="@CoreContext/SET_RESPONSE_ERRORS";