import React, { Fragment } from 'react';
import { permissions } from '../../../config/permission.config';
import guest from '../../hoc-middlewares/Guest';
import { Navigate } from '../../ui-components/ui-elements/common/Navigate';
import {ChangePassword} from './pages/change-password/ChangePassword';
import authorized from "../../hoc-middlewares/Authorized";

const ChangePasswordRoute = () => {
    return (
        <Fragment>
            <Navigate exact={true} path="/change-password" component={authorized(ChangePassword)} routerPermissions={permissions.NONE.permissions} />
        </Fragment>
    )
};

export default ChangePasswordRoute;