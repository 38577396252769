/*
 * @Author: Chamith Jayathilaka
 * @Date: 2022-02-09 12:10:00
 * @Last Modified by: Chamith Jayathilaka
 * @Last Modified time:  2022-02-09 12:10:00
 */

/**
 * @author Chamith Jayathilaka
 * @description download the file in relevant format
 * @param {blob} blob blob to download
 * @param {string} fileName filename with relavant file extention
 */

const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

export {
    downloadFile,

}
