import React from "react";
import { useContext } from 'react';
import { templateTypes } from "../../../../../config/template.config";
import { FormWrapper, DatePickerWithState, SelectBoxWithState, SubmitButton ,InputButtonWithState } from "../../../../ui-components/ui-elements/form/index";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import { _get } from '../../../../../helpers/common-helpers/lodash.wrappers';
import { reportGenerationMaxRangeMonths, reportDataLimit, reportDataOffset } from '../../../../../config/report.config'
import { reportGenerateAPI ,reportServiceGenerateAPI } from "../../../../../config/apiUrl.config";
import { isEmptyValue } from "../../../../../helpers/common-helpers/common.helpers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getDataByFormObject } from "../../../../../helpers/common-helpers/common.helpers";
import { AuthContext } from "../../../../modules/core/context-providers/AuthContext.provider";

const emptyFn = (...para) => undefined
const SearchElement = ({
  formGroupName,
  reportCode,
  setReportgeneratedValues = emptyFn,
  onGenerateSubmit = emptyFn,
  onResetClick =emptyFn,
}) => {

  const [formState, formAction] = useContext(FormContext);
  const [authStatus, authAction] = useContext(AuthContext);
  let date_from = new Date();
  let date_to = new Date();
  let status = "ALL"

  const setMinDateFn = (date) => {
    if (date) {
      let minDate = new Date(date);
      minDate = new Date(minDate.setDate(minDate.getDate()));
      return minDate;
    } else {
      return new Date(1970, 1, 1);
    }
  };

  const setMaxDateFn = (date) => {
    if (date) {
      let maxDate = new Date(date);
      maxDate = new Date(maxDate.setDate(maxDate.getDate()));
      return maxDate;
    } else {
      return new Date(2200, 1, 1);
    }
  };

  const setMaxDateRangeFn = (date) => {
    if (date) {
      let minDate = new Date(date);
      minDate = new Date(minDate.setMonth(date.getMonth() + reportGenerationMaxRangeMonths));
      return minDate;
    } else {
      return null;
    }
  };

  const setminDateRangeFn = (date) => {
    if (date) {
      let minDate = new Date(date);
      minDate = new Date(minDate.setMonth(date.getMonth() - reportGenerationMaxRangeMonths));
      return minDate;
    } else {
      return new Date(1970, 1, 1);;
    }
  };

  return (
    <FormWrapper
      setGroupName={formGroupName}
      formGroupLinkWith={formGroupName}
      onDestroyUnsetFormObject={true}
      setFormObject={{
        issued_from: null,
        dateTo: null,
      }}
    >

      <div className={"row"}>
        <div className="col-md-4 col-sm-12 floatLeft">
          <DatePickerWithState
            formGroupName={formGroupName}
            isRequired={true}
            inputName={"issued_from"}
            labelText={"Issued From"}
            format={'dd/MM/yyyy'}
            maxDate={setMaxDateFn(_get(formState[formGroupName], "dateTo", null))}
            minDate={setminDateRangeFn(_get(formState[formGroupName], "dateTo", null))}
          />
        </div>
        <div className="col-md-4 col-sm-12 floatLeft">
          <DatePickerWithState
            // elementStyle="MuiInputLabel-animated MuiInputLabel-shrink"
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            isRequired={true}
            inputName={"dateTo"}
            labelText={"Issued To"}
            format={'dd/MM/yyyy'}
            minDate={setMinDateFn(_get(formState[formGroupName], "issued_from", null))}
            maxDate={setMaxDateRangeFn(_get(formState[formGroupName], "issued_from", null))}
          />
        </div>
        <div className="col-md-4 col-sm-12 floatLeft ">
          <SelectBoxWithState
            uiType={templateTypes.bootstrap}
            formGroupName={formGroupName}
            isEnableAllOption={true}
            emptySelectOptionTxt={null}
            dataList={[
              {
                id: "Active",
                value: "Active",
              },
              {
                id: "Inactive",
                value: "Inactive",
              },
              {
                id: "Expired",
                value: "Expired",
              },
            ]}
            inputName={"status"}
            labelText="Status"
          />
        </div>

        <div className="col-md-12 col-sm-12 mt-2 report-btn-wrapper">
          <SubmitButton
            startIcon="fas fa-wrench"
            btnText={"Generate"}
            elementStyle="btnWrapper"
            formGroupName={formGroupName}
            isValidate={true}

            validationObject={{
              fileds: {
                issued_from: "Issued From",
                dateTo: "Issued To",
              },
              rules: {
                issued_from: "required",
                dateTo: "required",
              },
              message: {}
            }}
            callApiObject={{
              isSetHeaders: true,
              method: "get",
              multipart: false,
              onUpload: false,
              responseType: 'text'
            }}
            apiDataStoringObject={{
              setLoader: true,
              storingType: "API_RESPONSE_LINKED_FORM",
              mergeToSuccessResponse: true,
              mergeToErrorResponse: true
            }}
            onGetAPIEndPointFn={(formObject) => {

              if (!isEmptyValue(formObject.issued_from)) {
                date_from = dateObjectToString(new Date(formObject.issued_from));
              }
              if (!isEmptyValue(formObject.dateTo)) {
                date_to = dateObjectToString(new Date(formObject.dateTo));
              }

              if (!isEmptyValue(formObject.status)) {
                status = formObject.status
              }
      
              return {
                url: `${reportGenerateAPI.url}?report_code=${reportCode}&report_format=html&report_limit=${reportDataLimit}&report_offset=${reportDataOffset}&report_requestFromDate=${date_from}&report_requestToDate=${date_to}&report_requestStatus=${status}&report_generatedUser=${_get(authStatus, 'authUser.userName', "")}`,
                key: `${reportGenerateAPI.key}`
              }
            }}
            onChangeRequestBodyFn={(formObject) => {
              return getDataByFormObject(formObject);
            }}
            onResponseCallBackFn={(error, response,) => {
              if (response?._statue) {
                onGenerateSubmit(response?.data)
                setReportgeneratedValues({
                  date_from,
                  date_to,
                  limit: reportDataLimit,
                  offset: reportDataOffset,
                  status: status,
                  reportGeneratedUser: _get(authStatus, 'authUser.userName', "")
                })
              }


            }}
          />

<InputButtonWithState
                                    btnText="Reset"
                                    startIcon="fas fa-sync-alt"
                                    elementWrapperStyle={"refreshBtn"}
                                    formGroupName={formGroupName}
                                    mergeToForm={{
                                      issued_from: null,
                                      dateTo: null,
                                      status: null
                                    }}
                                    onClickBtnFn={(event) => {
                                      formAction.setFormErrorFn(formGroupName)
                                      onResetClick()
                                        // let searchObject = event.formObject || {};

                                        // if (onSetSearchObjectFn !== false) {
                                        //     searchObject = onSetSearchObjectFn(defaultSearchFormObject || {});
                                        // }

                                        // setDataTableStateFn({
                                        //     ...dataTableState,
                                        //     formObject: searchObject
                                        // });
                                        // onGetData("searchFrom", searchObject);
                                    }}
                                />
        </div>
      </div>
    </FormWrapper>
  );
};
export { SearchElement };