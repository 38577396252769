import React from "react"
import { _get } from "../../../../../helpers/common-helpers/lodash.wrappers";
import { dateObjectToString } from "../../../../../helpers/common-helpers/dateTime.helpers";
import { getRoutePermitStatus } from '../../../../../helpers/common-helpers/getRoutePermitStatus.helper'
import { getDecodedValue } from "../../../../../helpers/common-helpers/common.helpers";

const RoutePermitInfo = ({
  getState = {}

}) => {

  const getExpiryDate = (date) => {
    if (date) {
      return dateObjectToString(_get(date, "date", ''), "dd/mm/YY")
    } else {
      return ''
    }
  }
  return (
    <div className="row">
      <div className="col-md-6  ">
        <strong>Vehicle ID: </strong>  {getState.vehicleObject?.vehicleId}
      </div>
      <div className="col-md-6">
        <strong>Last Six Characters of the Chassis Number: </strong>{`${getDecodedValue(getState.vehicleObject?.chassisNumber, true, false)}`.slice(-6)}
      </div>
      <div className="col-md-6">
        <strong>Current RL No.: </strong> {getState.vehicleObject?.currentRL}
      </div>
      <div className="col-md-6">
        <strong>RL Expiry Date: </strong> {_get(getState.vehicleObject, "rlExpiryDate", "") ? dateObjectToString((_get(getState.vehicleObject, "rlExpiryDate", "")), "dd/mm/YY") : "-"}
      </div>
      <div className="col-md-6">
        <strong>Route Permit Number: </strong> {getState.routePermitObject?.routePermitNo}
      </div>
      <div className="col-md-6">
        <strong>Status: </strong> {getRoutePermitStatus(getState.routePermitObject.status, _get(getState.routePermitObject, "expiryDate"))}
      </div>
      <div className="col-md-6">
        <strong>Issued Date: </strong>  {_get(getState.routePermitObject, "issuedDate", "") ? dateObjectToString(_get(getState.routePermitObject, "issuedDate", ""), "dd/mm/YY") : '-'}
      </div>
      <div className="col-md-6">
        <strong> Expiry Date: </strong> {_get(getState.routePermitObject, "expiryDate", "") ? dateObjectToString(_get(getState.routePermitObject, "expiryDate", ""), "dd/mm/YY") : '-'}
      </div>
    </div>
  );
};

export { RoutePermitInfo }