import React, { useContext } from "react";
import { FormContext } from "../../../core/context-providers/FormContext.provider";
import {
    FormWrapper,
    InputBoxWithState,
    SubmitButton,
    DatePickerWithState
  } from "../../../../ui-components/ui-elements/form";
  import { templateTypes } from "../../../../../config/template.config";
  import { _get , } from "../../../../../helpers/common-helpers/lodash.wrappers";
  import { getRoutePermitStatus } from '../../../../../helpers/common-helpers/getRoutePermitStatus.helper'

const ReissuePermit = (props) => {
    const formKey = "reissueRoutePermit";
    const [formState, formAction] = useContext(FormContext);
    return (
    
      <div className="col-md-12">
        <FormWrapper
          setGroupName={formKey}
          setFormObject={{
            id: _get(props.getState.vehicleObject, "id", undefined),
            vehicle_id: _get(props.getState.vehicleObject, "vehicleId", ""),
            status:getRoutePermitStatus(props.getState.routePermitObject.status,props.getState.routePermitObject.expiryDate),
            routePermitId:_get(props.getState.routePermitObject, "routePermitId", ""),
            new_rp_no:_get(props.getState.routePermitObject, "routePermitNo", ""),
            expiry_date:getExpiryDate()
          }}
        >
          <InputBoxWithState
            labelText={"Vehicle ID"}
            formGroupName={formKey}
            inputName={"vehicle_id"}
            inputValue={props.getState.vehicleObject.vehicleId}
            isDisabled='true'
          />
          <InputBoxWithState
            labelText={"Current Permit No."}
            formGroupName={formKey}
            inputName={"rp_number"}
            inputValue={props.getState.routePermitObject.routePermitNo}
            isDisabled={true}
          />
          <DatePickerWithState
             labelText={"Issued Date"}
                    formGroupName={formKey}
                    inputName={"issued_date"}
                    format={'dd/MM/yyyy'}
                    inputValue={props.getState.routePermitObject.issuedDate}
                    isDisabled={true}
                />
            <DatePickerWithState
                  labelText={"Expiry Date"}
                       formGroupName={formKey}
                    inputName={"expiry_date_old"}
                    format={'dd/MM/yyyy'}
                    inputValue={props.getState.routePermitObject.expiryDate}
                    isDisabled={true}
                />
  
          <InputBoxWithState
            labelText={"Status"}
             formGroupName={formKey}
            inputName={"issued_date"}
            inputValue={getRoutePermitStatus(props.getState.routePermitObject.status,props.getState.routePermitObject.expiryDate)}
            isDisabled={true}
          />
          <div className="serialNumberWrapper">
            <InputBoxWithState
              isRequired ={true}
              uiType={templateTypes.bootstrap}
              labelText={"Route Permit No."}
               formGroupName={formKey}
              inputName={"new_rp_no"}
              inputValue={props.getState.routePermitObject.routePermitNo}
              isDisabled={false}
              maxLength="20"
            />
          </div>
  
  
          <DatePickerWithState
                          labelText={"Expiry Date"}
                          isRequired ={true}
                          formGroupName={formKey}
                          inputName={"expiry_date"}
                          inputValue={getExpiryDate()}
                          format={'dd/MM/yyyy'}
                          isDisabled={false}
                          minDate={new Date()}
                          maxDate={getExpiryDate()}
                        />
          
         
        </FormWrapper>
      </div>
    )
  };

  /**
* @description: returns the expiry date currently 1 year from current date and remove a day
*/
const getExpiryDate=()=>{
  // get today and substract 1 day
  let today = new Date();
  today.setDate(today.getDate()-1);
  return new Date(today.setFullYear(new Date().getFullYear() + 1))
}

  
  export {ReissuePermit}