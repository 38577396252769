/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-05-23 18:02:24 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-07-08 10:15:29
 */
import React,{Fragment} from 'react';

import {_get} from "../../../../../helpers/common-helpers/lodash.wrappers";
import {  VariableToComponent } from "../../common/BaseElements";
import { DialogPopup } from "../../modal/DialogPopup";

const emptyFun = (...para) => undefined;

const EmptyComponent = () => <Fragment></Fragment>

const CURDPops=({
    dataTableState={},
    actionTypeList=[],
    setDataTableStateFn=emptyFun,
    onSubmitCurdFn=emptyFun
})=>{
    return (
            <DialogPopup
                isFullScreen={true}
                isOpen={dataTableState.modelStatus.status}
                isCloseButton={true}
                isSaveButton={true}
                onClosePopupFn={() => setDataTableStateFn({
                    ...dataTableState,
                    modelStatus: {
                        ...dataTableState.modelStatus,
                        status: false,
                        index: null,
                        rowIndex:null,
                        props: {},
                    }
                })}
                onSaveFn={(formData) => onSubmitCurdFn(formData,_get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.parentToModelProps.formKey`, "none"))}
                formGroupName={_get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.parentToModelProps.formKey`, "none")}
                {..._get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.dialogPopupProps`, {})}
            >
                <VariableToComponent
                    component={_get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.modelContentElement`, EmptyComponent)}
                    props={{
                        "row": _get(dataTableState, 'modelStatus.props', {}),
                        "fromParent": _get(actionTypeList, `${_get(dataTableState, 'modelStatus.index', "none")}.parentToModelProps`, {}),
                        "onClosePopupFn":()=>setDataTableStateFn({
                            ...dataTableState,
                            modelStatus: {
                                ...dataTableState.modelStatus,
                                status: false,
                                index: null,
                                rowIndex:null,
                                props: {},
                            }
                        })
                    }}
                />
            </DialogPopup>
    )
}


export {
    CURDPops
}