/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-06-23 10:10:15 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-06-23 10:19:57
 */
import React, { useContext, useState } from 'react';
import { InputButton } from '../../../ui-components/ui-elements/form';
import { CoreContext } from "../../../modules/core/context-providers/CoreContext.provider";
import { _get } from '../../../../helpers/common-helpers/lodash.wrappers';
import { userLogoutAPI } from "../../../../config/apiUrl.config";
import {logoutUser,checkUserINLocalStorage} from '../../../../helpers/common-helpers/manageStorage.helpers';

const Forbidden=()=>{
    const [coreState, coreAction] = useContext(CoreContext);
    const logoutApiCall = () => {
        const requestBody = {
          //client_id:_get(authStatus, 'keycloak.clientId', null),
          refresh_token: _get(checkUserINLocalStorage(), 'result.refreshToken', null)
        }
    
        coreAction.sendRequestFn(userLogoutAPI.url)
          .isMultipart(false)
          .method("post")
          .body(requestBody)
          .setLoader(true)
          .setInitStoring("none", null)
          .headers(true)
          .send((error, result) => {
             logoutUser();
             window.location.reload();
            // if (!error) {
            //   authAction.unauthorisedUserFn();

            // }
          });
    
      }
    return (
        <div className="errorBackGround">
            <div className="itemWrapper">
                <h1>Forbidden...!!</h1>
                    <InputButton
                        btnText="Go back to Login"
                        onClickBtnFn={()=>{
                            logoutApiCall()
                          }}
                    >
                    </InputButton>
                
            </div>
        </div>
    )


}




export {
    Forbidden
}