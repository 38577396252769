/*
 * @Author: Nisal Madusanka(EruliaF)
 * @Date: 2020-03-21 09:25:21
 * @Last Modified by: Sujith
 * @Last Modified time: 2021-03-10 12:22:19
 * @Last Modified by: Nisal Madusanka(EruliaF)
 */

import {
    setAuthTokenKey,
    setUnauthorisedUserKey,
    setAuthUserObjectKey,
  } from '../../../../config/actionKeys.config';
  import {
    logoutUser,
  } from '../../../../helpers/common-helpers/manageStorage.helpers';
  
  /**
   * @Author: Nisal Madusanka(EruliaF)
   * @description : set user auth token
   * @param {Function} dispatch auth  dispatch function
   * @param {Object} tokenObject
   */
  const setTokensFn = (dispatch, tokenObject) => {
    dispatch({
      type: setAuthTokenKey,
      playload: tokenObject,
    });
  };
  
  /**
   * @Author: Nisal Madusanka(EruliaF)
   * @description : unauthoried user
   * @param {Function} dispatch auth  dispatch function
   */
  const unauthorisedUserFn = (dispatch) => {
    logoutUser();
    dispatch({
      type: setUnauthorisedUserKey,
    });
  };
  
  /**
   * @Author: Nisal Madusanka(EruliaF)
   * @description : set user auth token
   * @param {Function} dispatch auth  dispatch function
   * @param {Object} userObject
   */
  const setAuthUserFn = (dispatch, userObject) => {
    dispatch({
      type: setAuthUserObjectKey,
      playload: userObject,
    });
  };
  
  /**
   * @Author: Nisal Madusanka(EruliaF)
   * @description : unauthoried user
   * @param {Function} dispatch auth  dispatch function
   * @param {Function} uiDispatch ui  dispatch function
   * @param {Object} formObject form object
   * @param {Object} formAction form dispatch function
   */
  const onLoginFn = (
    dispatch,
    uiDispatch,
    formObject,
    formAction,
    formGroupKey
  ) => {
    // uiDispatch.setPageLoader(true);
    // const id = '5ec28243d993f70026fc09e4'
    // const secret = '15c20ec9101f914713040cA296d492a3aceec350fef7f'
    // const token = Buffer.from(`${id}:${secret}`, 'utf8').toString('base64')
    // const requestBody = {
    //     username: formObject.username || "",
    //     password: formObject.password || "",
    //     grant_type: "password",
    // }
    // var authOptions = {
    //     method: 'POST',
    //     url: authAPI.url,
    //     data: qs.stringify(requestBody),
    //     headers: {
    //         'Authorization': `Basic ${token}`,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    // };
    // axios(authOptions)
    //     .then(function (response) {
    //         uiDispatch.setPageLoader(false);
    //         if (response.data.access_token) {
    //             setAuthData(response.data);
    //             setTokensFn(dispatch, response.data);
    //             return;
    //         } else {
    //             return alert("Error...!!")
    //         }
    //     })
    //     .catch(function (error) {
    //         uiDispatch.setPageLoader(false);
    //         if (_get(error.response, "data.error", "") === "INACTIVE_USER") {
    //             uiDispatch.setFlashMessage({
    //                 status: true,
    //                 message: "User is inactive in the system",
    //                 messageType: "error"
    //             });
    //         } else {
    //             return formAction.setFormErrorFn(formGroupKey, [
    //                 {
    //                     "property":"username",
    //                     "message":"Invalid username or password"
    //                 },
    //                 {
    //                     "property":"password",
    //                     "message":"Invalid username or password"
    //                 }
    //             ]);
    //         }
    //     });
  };
  
  /**
   * @author Nisal Madusanka (EruliaF)
   * @description connect all methods as one
   * @param {Object} dispatch
   */
  const authAction = (dispatch, uiDispatch) => {
    return {
      setTokensFn: (tokenObject) => setTokensFn(dispatch, tokenObject),
      unauthorisedUserFn: () => unauthorisedUserFn(dispatch),
      setAuthUserFn: (userObject) => setAuthUserFn(dispatch, userObject),
      onLoginFn: (formObject, formAction, formGroupKey) =>
        onLoginFn(dispatch, uiDispatch, formObject, formAction, formGroupKey),
    };
  };
  
  export { authAction };
  